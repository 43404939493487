import { env, civil,Litigation, business, Employment, Policy, Energy, RealEstate, Probate,  } from "../assets";




function replaceSubstring(originalString, substringToReplace, replacementSubstring) {
  return originalString.replace(substringToReplace, replacementSubstring);
}

const inputString = '#home';
const replacedString = replaceSubstring(inputString, '#home', '')

export const services = [
    {
     title:'0',
     main:'Energy Law & Natural Resources',
     role:'Energy Law & Natural Resources',
     imgUrl: 'https://master-in-mobility.com/wp-content/uploads/pexels-photo-532192-2.jpeg' 
    },
    {
      title: "4",
      main: "Corporate and Commercial Law",
      role: 'Commercial Law',
      imgUrl:'https://www.latestlaws.com/media/2022/04/commercial-law-0-1649223929.jpg'

    },
    {
      title: "3",
      main: "Estate Planning and Probate Adminstration",
      role: 'Civil Law',
      imgUrl: 'https://papercliplaw.com/wp-content/uploads/shutterstock_293654537.jpg'
      
    },
    {
      title: "2",
      main: "Dispute Resolution",
      role: "Dispute Resolution", 
      imgUrl:'https://lfap.com/wp-content/uploads/2019/01/adobestock_168954202-e1546456460397.jpeg'
     
    },
    {
      title: "1 ",
      main: "Environmental Law ",
      role: 'Environmental Law',
      imgUrl:'https://www.theforage.com/blog/wp-content/uploads/2022/10/environmental-law-1.jpg'
    },
    {
      title: "6",
      main: "Employment & Labour Relations ",
      role: 'Employment & Labour Relations',
      imgUrl:'https://media.oralhealthgroup.com/uploads/2021/05/employment-law-oho.jpg'
    },
    {
      title:'7',
      main:'Policy & Legislative Drafting',
      role:'Policy & Legislative Drafting',
      imgUrl:'https://ronoandpartners.com/wp-content/uploads/2019/10/free_it_policy_templates_287157809.jpg'
    },
    {
      title:'5',
      main:'Real Estate, Banking & Finance',
      role:'Real Estate, Banking & Finance',
      imgUrl:'https://jbsolicitors.com.au/wp-content/uploads/2022/04/oiprnim08gx.jpg'
    }
    
  
  ];

export const testimonials = [
  {
   title:'Mr. Hiu',
   occupation:'Banker',
   testimony:" Their expertise, attention to detail, and strategic thinking helped me win my case. They were not only brilliant in the courtroom, but they also provided constant support and guidance throughout the process. Gekonge and Advocates truly has the best lawyers who are dedicated to fighting for their clients' rights.",
   
  },
  {
    title:'John Wekesa',
    occupation:'Civil Engineer',
    testimony:"Their negotiation skills and legal acumen were instrumental in achieving a favorable settlement. I cannot speak highly enough of Gekonge and Advocates Law Firm and their exceptional team of lawyers who genuinely care about their clients and deliver outstanding results.",
    
  },
  {
    title:'Omollo Omollo',
   occupation:'Creative Designer',
   testimony:"They meticulously analyzed my case, leaving no stone unturned. Their persuasive arguments and exceptional courtroom skills helped me achieve a favorable verdict. Gekonge and Advocates Law Firm truly has the best lawyers who are not only skilled but also deeply committed to their clients' success. I am incredibly grateful for their support throughout the legal process.",
 
  }
]


// export const intro=[
//   {
//    id:1,
//    text: 'Unmatched legal expertise for superior consultancy'
//   },
//   {
//    id:2,
//    text:"Precision Law, Empowering You to Prevail, Your Path to Justice"
//   },
//   {
//     id:3,
//     text:"Experience the Difference: Excellence in Law, Kenya's Finest, Advocating for You."
//   }

// ]


export const intro=[

   'Unmatched legal expertise for superior consultancy',
   " Empowering You to Prevail, Your Path to Justice",
   " Kenya's Finest, Advocating for You."

]

export const names=[
  {
    title: 'Edwin Gekonge',
    role:"CEO"
  },
  {
    title: 'Lilian Oloo',
    role:"Civil Lawyer"
  },
  {
    title: 'Ian Mwangi',
    role:"Family Lawyer"
  },
]

export const navLinks = [
  {
    id: '',
    title: "Home",
  },
  // {
  //   id: "MiniCarousel",
  //   title: "Legal Counsel",
  // },
  {
    id: "firm",
    title: "The Firm",
  },
   {
    id: "Team",
    title: "Our Team",
  },
  {
    id: "services",
    title: "Practice Areas",
    button:"\u2193"
  },
  {
    id: "blog",
    title: "News & Insights",
  },
  {
    id: "contact",
    title: "Contact",
  },



]
  export const navLinks2 = [
    {
      id: replacedString,
      title: "Home",
    },
    {
      id: "MiniCarousel",
      title: "Legal Counsel",
    },
    {
      id: "about",
      title: "The Firm",
    },
    {
      id: "services",
      title: "Practice Areas",
    },
    {
      id: "contact",
      title: "Contact",
    },
  // {
  //   id: "articles",
  //   title: "Articles",
  // },
]

export const specialties = [
  {
    title: "1",
    icon: env,
    role:'Environment Law',
    intro:'We provide an efficient service and pragmatic advice to a wide array of clients, both in the public and private sectors. We have extensive experience in issues relating to environmental risk for businesses,  legal issues relating to energy, property development and general commercial activity'
  },
  {
   title: "4",
    icon: business,
    role:"Corporate & Commercial Law",
    intro:'We advise our local and international clients on complex transactions'
  },
  {
    title: "2",
    icon: Litigation,
    role:"Dispute Resolution",
    intro:'The Firm has a well-established in criminal and commercial litigation department supported by strong teams of legal associates. '
  },
  {
    title:"6",
    icon: Employment,
    role : 'Employment & Labour Relations ',
    intro:'We advise businesses, corporations and individuals across the spectrum of labor and employment-related matters'
  },
  {
    title:"7",
    icon:Policy,
    role : 'Policy & Legislative Drafting ',
    intro:"We assist our clients in promoting policy change, contributing to the drafting of legislation, and managing regulatory risk that could affect their businesses"
  },
  {
    title:"0",
    icon:Energy,
    role : 'Energy Law & Natural Resources ',
    intro:'Our team is strategically placed in leading practices in energy project development and finance, governmental energy funding,'
  },
  {
    title:"5",
    icon: RealEstate,
    role : 'Real Estate, Banking & Finance',
    intro:'The Firm has extensive knowledge and experience in providing excellent services to clients in a wide range of proprietary transactions. We pride ourselves in giving our clients quality legal advice based on sound due diligence in an area where utmost caution and efficiency are encouraged.'
  },
  {
    title:'3',
    icon:Probate,
    role:'Estate Planning & Probate Administration',
    intro:"The firm a trusted advisor in the realm of estate planning, guiding individuals and families in crafting personalized strategies to protect and distribute their assets through an approach tailored to meet the specific needs and goals of those seeking to secure their legacy."
  }
  
]