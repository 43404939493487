import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import data from '../data/data'
import { useEffect, useState } from 'react'
import { motion, Variants } from 'framer-motion';
import ReactPlayer from 'react-player';
import { useCarouselContext } from '../context';
import CustomButton from '../components/CustomButton'
import { fadeIn } from '../utils/utils';


const importAll=(r)=>
  r.keys().reduce((acc,item)=>{
    acc[item.replace("./", "")] = r(item);
    return acc
  }, {})

 const heroTextureImports = importAll(
    require.context("../assets", false, /\.(png|jpe?g|svg|gif)$/)
)

const carouselData = [
  {
    imageUrl: 'https://t4.ftcdn.net/jpg/05/71/76/25/360_F_571762531_uupDdpj93rLPrIxiWFfhvdRjWW7a9Iid.jpg',
    helper:'Your Trusted Legal Advisors',
    text:'Committed to Excellence in Legal Representation'
  
  },
  {
    imageUrl: 'https://c0.wallpaperflare.com/preview/208/82/396/backgrounds.jpg',
    helper: '--Our Values--',
    text:'Excellence, Discipline and focus',
  },
  {
    imageUrl: 'https://c4.wallpaperflare.com/wallpaper/307/345/278/scales-wallpaper-preview.jpg',
    helper:'--With Our Services--',
    text:"Elevating Justice through Delivering Solutions"
  },
  {
    imageUrl: 'https://images.unsplash.com/photo-1455390582262-044cdead277a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjZ8fGxhd3llcnxlbnwwfHwwfHx8MA%3D%3D',
    helper:'--We Offer--',
    text:"Excellence in Advocacy, Dedication to Your Legal Paths",
  },
  
  // Add more image and text data as needed
];

const cardVariants: Variants = {
  offscreen: {
    x: 315,
  
  },
  onscreen: {
    x: 0,
    
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.9
    }
  }
};

const fadeInFromBelow = {
  hidden: { opacity: 0, y: 140 }, // Initial state: hidden and slightly below
  visible: { opacity: 1, y: 0 }, // Final state: visible and at its original position
};

const MainCarousel = (styles) => {
  const images= Object.values(heroTextureImports);
  //const {letOn}= useCarouselContext()
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
   const [activeSlideIndex, setActiveSlideIndex] = useState(0);
   

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const previousSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const videoUrl = "../assets/7.gif"

  const handleSlideChange = (index) => {
    // Update the active slide index
    
    setActiveSlideIndex(index);

    
  };
  return (
 <section className={styles}>
    <Carousel
    showThumbs={false}
    infiniteLoop={true}
    autoPlay={true}
    interval={7000}
    transitionTime={500}
    showStatus={false}
    showIndicators={false}
    showArrows={true}
    onChange={handleSlideChange}
  >
    {carouselData.map((item, index) => (
        <div key={index} className=' bg-white  flex justify-center'>
          <motion.div  initial="hidden"
          animate={activeSlideIndex === index ? 'visible' : 'hidden'}
          variants={fadeInFromBelow}
          transition={{ duration: 1 }}
           className="flex flex-col items-center justify-center w-3/4 text-white font-custom text-4xl text-center font-bold z-10 md:mt-48 mt-24 absolute">
            <h1 className='text-yellow-600'>{item.helper}</h1>
            <p className='font-custom sm:text-5xl text-2xl mt-5'>{item.text}</p>
           {/* <div className=" absolute text-2xl flex justify-center sm:w-1/3 w-full sm:mt-56 mt-64 ">
              <CustomButton
               title='Consultation'/>
           </div> */}


          {/* <span class="md:text-6xl text-3xl md:text-center text-center">{item.text.split(' ')[0]}</span>
          <span class="md:text-6xl text-3xl md:text-center tex-center">{item.text.split(' ').slice(1).join(' ')}</span> */}
          {/* <span class="text-6xl text-left">{item.text.split(' ').slice(3).join(' ')}</span> */}
        </motion.div>
           {/* <h1 className="text-white font-custom text-4xl font-bold flex z-50 mt-56 left-20 absolute " >{item.text}</h1> */}
          <img src={item.imageUrl} alt={`Slide ${index + 1}`} className='md:w-full w-1/2 md:h-[570px] h-[400px] sm:mt-7 mt-0  object-cover' style={{ backgroundAttachment:"fixed"}} />
         
        </div>
      ))}
    {/* <div>
       
      <img  style={{width: "100%", height:'650px', objectFit: "cover", backgroundAttachment:"fixed"}} src={images[currentIndex]} alt="Image 1" />
    </div>
    <div>
      <img style={{width: "100%",height:'650px',objectFit: "cover", backgroundAttachment:"fixed"}} src={images[5]} alt="Image 2" />
    </div>
    <div>
      <img style={{width: "100%",height:'650px',objectFit: "cover", backgroundAttachment:"fixed"}} src={images[6]} alt="Image 3" />
    </div> */}
  </Carousel>
  </section>
  )
}

export default MainCarousel