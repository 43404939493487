import React, {useContext, useState, createContext, useRef, useEffect} from "react";
import { getDescriptions, getImages } from "../utils/firebase.utils";
import { getParagraphs } from "../utils/firebase.utils";
import { getTexts } from "../utils/firebase.utils";
import { getBlogs } from "../utils/firebase.utils"

 const CarouselContext = createContext({
  currentIndex: 0,
  setCurrentIndex:()=>{},
  activeSlideIndex:0,
  handleSlideChange:()=>{},
  topics:{},
  topics2:[],
  images:[],
  texts:[],
  contactsSectionRef:{},
  scrollToContacts:()=>{}
 })


export const CarouselContextProvider= ({children})=>{
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itsOn, setItsOn]=useState(true)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const[topics, setTopics]=useState({})
  const[topics2, setTopics2]=useState([])
  const[images, setImages]=useState([])
  const[texts, setTexts] = useState([])
  const contactsSectionRef = useRef(null);
  const[isMobile, setIsMobile] = useState(true)
  const[isMidMobile, setIsMidMobile] = useState(true)
  const [blogs, setBlogs] = useState({})

  useEffect(()=>{
      //Add a listener for changes to the screen size
      const mediaQuery = window.matchMedia('(max-width: 500px)');
  
      //Set the initial value of the 'isMobile' state variable
      setIsMobile(mediaQuery.matches)
  
      //Define a callback function to handle changes to the media query
      const handleMediaQueryChange =(event)=>{
        setIsMobile(event.matches)
      }
  
      //Add the callback function as a listener for change to the media query
      mediaQuery.addEventListener('change', handleMediaQueryChange)
      
      //Remove the listener when the component is unmounted
      return()=>{
        mediaQuery.removeEventListener('change',handleMediaQueryChange)
      }
    }, [window.matchMedia])
  
  
    useEffect(()=>{
      //Add a listener for changes to the screen size
      const mediaQueryMid = window.matchMedia('(max-width: 1000px)');
  
      //Set the initial value of the 'isMobile' state variable
      setIsMidMobile(mediaQueryMid.matches)
  
      //Define a callback function to handle changes to the media query
      const handleMediaQueryChange =(event)=>{
        setIsMidMobile(event.matches)
      }
  
      //Add the callback function as a listener for change to the media query
      mediaQueryMid.addEventListener('change', handleMediaQueryChange)
      
      //Remove the listener when the component is unmounted
      return()=>{
        mediaQueryMid.removeEventListener('change', handleMediaQueryChange)
      }
    }, [window.matchMedia])

  const scrollToContacts = () => {
    contactsSectionRef.current.scrollIntoView({ behavior: "smooth" });
   
  };



   const letOn=(()=>{
    if(!itsOn){
        setItsOn(false)
    }
    setItsOn(true)
   })

 
   useEffect(()=>{
    const getTopic= async()=>{
    const topic= await getDescriptions()
    setTopics(topic)
   }
    getTopic()
  },[])

  useEffect(()=>{
    const getText= async()=>{
    const topic= await getParagraphs()
    setTopics2(topic)
   }
    getText()
  },[])


  useEffect(()=>{
    const getImage= async()=>{
    const topic= await getImages()
    setImages(topic)
   }
    getImage()
  },[])
 

  useEffect(()=>{
    const getText= async()=>{
    const topic= await getTexts()
    setTexts(topic)
   }
     getText()
  },[])


  useEffect(()=>{
    const getBlogPost = async()=>{
      const blog = await getBlogs()
      setBlogs(blog);
    }
    getBlogPost()
    
  },[])
  console.log('blogs', blogs)

     const value={topics, letOn, topics2, images, texts, contactsSectionRef, scrollToContacts, isMobile, isMidMobile, blogs}
    return(
        <CarouselContext.Provider value={value}>
            {children}
        </CarouselContext.Provider>
    )
}

export const useCarouselContext = () => useContext(CarouselContext)



 