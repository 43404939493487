import React,{useState} from 'react'
import { BsFacebook } from 'react-icons/bs'
import { BsWhatsapp } from 'react-icons/bs'
import { AiOutlineTwitter } from 'react-icons/ai'
import {BsLinkedin} from 'react-icons/bs'
import { Logo2 } from '../../assets'
import {FiSend} from 'react-icons/fi'
import { navLinks } from '../../constants'
import { specialties } from '../../constants'
import { Link } from 'react-router-dom'

const Footer2 = () => {
  const[active, setActive] = useState("")
  return (
  <div className='w-full sm:h-1/2 h-1/4 mt-[0px] bg-yellow-900  shadow-lg'>
   <div className='grid sm:grid-cols-4 sm:px-0 px-12 sm:gap-3  gap-0 sm:space-y-10  space-y-5 font-custom text-white'>
         <div className='-mt-8'>
           <img src={Logo2} alt='' className='sm:w-[400px] w-[200px] sm:h-[200px] h-[100px] mt-24 sm:p-6 p-2'/>  
           {/* <h1 className='text-white'>About Us</h1> */}
           {/* <p className='-mt-20 flex'>Our law firm stands out as the premier legal destination in Kenya, 
            thanks to our exceptional team of lawyers. With their extensive expertise,
             unwavering dedication, and a proven track record of success, 
             our lawyers consistently deliver outstanding results for our clients.
             </p> */}
         </div>
         <div className='mt-28 space-y-4'>
           <div className='flex flex-col space-y-2'>
           <h1 className='text-white font-bold'>Practice Area</h1> 
           {specialties.map((image, index) => (
             
              
                    <Link className='hover:text-black font-custom mt-5 text-1xl text-left font-bold z-10' to={`${image.title}`} >{image.role}</Link>

           ))}
             {/* <h1 className='text-white font-bold'>Practice Area</h1> 
             <h1>Energy Law & Natural Resources</h1>
             <h1>Corporate & Commercial Law</h1>
             <h1>Litigation, Arbitration & Mediation</h1>
             <h1>Environmental Law</h1>
             <h1>Employment & Labour Relations</h1>
             <h1>Real Estate, Banking & Finance</h1>
             <h1>Estate Planning & Probate Administration</h1>
             <h1>Policy & Legislative Drafting</h1>
              */}
           </div>
         </div>
         <div className='mt-28 space-y-2'>
         <h1 className='text-white font-bold'>Links</h1>
         <ul className='flex flex-col  space-y-1 '>
                 {navLinks.map((link)=>(
                    <li key={link.id} className={`${
                     active===link.title
                     ? "text-black"
                     : "text-white"
                   } hover:text-black text-[18px] font-custom cursor-pointer z-10`}
                    onClick={()=>setActive(link.title)}
                   >
                     
                      <a href={`#${link.id}`}>{link.title}</a>
                      {/* <span className="text-1xl inline-block px-2 rotate-90">&#8250;</span> */}
                     
                    </li>
                 ))}
                  
               </ul> 
           {/* <h1 className='text-white font-bold'>Links</h1>
           <a href={`#service`}>Home</a>
           <h1>Legal Counsel</h1>
           <h1>The Firm</h1>
           <h1>Practice Areas</h1>
           <h1>Contact</h1> */}
         </div>
         <div className='mt-28 space-y-2'>
           <h1 className='text-white font-bold'>Our Address</h1>
           <div className=''>
            <h1>Britam Towers</h1>
            <h1>24th Floor, Regus, Suite 2b</h1>
            <h1>P.o Box 59091-00100</h1>
            <h1>Nairobi</h1>
            <h1>Office Line: +254 709 385 074</h1>
            <h1>Email: gekonge@gekongemomanyi.co.ke</h1>
           </div>
          
         </div>

       </div>
       
       <div className='text-yellow-600 flex sm:mt-[16px] mt-4 sm:flex-row flex-col justify-center sm:px-0 px-12 sm:space-x-10 space-x-0  '>
         <span >Copyright 2023@Gekonge Momanyi & Associates</span>
         <span>Private & Policy</span>
         <span>Terms</span>
         <span>About Us</span>
         <span>FAQ</span>
       </div>
      
   </div>
  )
}

export default Footer2