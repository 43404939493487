import React, {useState , useEffect} from 'react'
import MainCarousel from '../components/MainCarousel'
import Navbar from '../components/Navbar'
import Services from '../components/Services'
import Profile from '../components/About'
import Display from '../components/Display'
import Footer from '../components/Footer'
import MainLocation from '../components/MainLocation'
import Articles from '../components/Articles'
import Testimonials from '../components/Testimonials'
import Team from '../components/Team'
import MiniCarousel from '../components/MiniCarousel'
import MiniCarousel2 from '../components/MiniCarousel2'
import Display2 from '../components/Display2'
import NavbarMain from '../components/NavbarMain'
import Contact from '../components/Contact'
import MainContact from '../components/MainContact'
import Loader from '../hoc/Loader'
import Loader2 from '../hoc/Loader2'
import Blog from '../components/Blog'
import { useParams } from 'react-router-dom'
import { useRef } from 'react'
import {Helmet} from 'react-helmet-async'


const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating a data loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);


  const { divId } = useParams();
  const targetDivRef = useRef(null);
  console.log(divId)
  useEffect(() => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [divId]);
  return (
    <>
    <Helmet>
      <title>GEKONGE MOMANYI AND ASSOCIATES</title>
      <meta name="description" content="Gekonge momanyi and associates is a law firm with the most experienced lawyers in various law fields"/>
      <link rel='canonical' href='/'/>
    </Helmet>
    { isLoading? <Loader2/> :
    <div className='bg-white' >
      <NavbarMain/> 
       <Display/>
      <MiniCarousel/>
       <Display2/> 

       <Services/>

       <MainContact/>
       <Blog/>
      <Footer/>
      

    </div>  
      }
    </>
  )
}

export default Home