import React,{useState} from 'react'
import SectionWrapper from '../hoc/SectionWrapper';
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'; 
import { BriefCase } from '../assets';
import { names } from '../constants';


const importAll=(r)=>
  r.keys().reduce((acc,item)=>{
    acc[item.replace("./", "")] = r(item);
    return acc
  }, {})

 const heroTextureImports = importAll(
    require.context("../assets/profs", false, /\.(png|jpe?g|svg|webp)$/)
)
const Team = () => {
  const images= Object.values(heroTextureImports);
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const previousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3, // Number of slides to display at a time on desktop
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2, // Number of slides to display at a time on tablet
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1, // Number of slides to display at a time on mobile
    },
  };
  return (
    <div className='-mt-6 shadow-lg bg-white'>
      <div className='flex flex-col items-center'>
        <img src={BriefCase} className='w-[50px] h-[50px] shadow-lg' />
        <h1 className='text-2xl font-custom font-semibold'>Meet our Experts</h1>
        <p className='text-semibold font-custom space-y-4 tracking-wide '>The best advocates with highly skilled legal expertise<br/>
         and have a reputation for providing exceptional legal services.</p>
      </div>
         <div className='w-full h-full py-12 relative shadow-lg'>  
          <Carousel
           responsive={responsive}
           infinite
           //infinite
          //  arrows={false} // Optional: Hide arrows for a cleaner look
          //  showDots={false} // Optional: Hide dots for a cleaner look
          //  swipeable={true} // Enable swipe gestures
          //  draggable={true} // Enable mouse dragging
          //  ssr={false} // Server-side rendering (set to false for client-side rendering)
          //  keyBoardControl={true} // Enable keyboard control
          //  transitionDuration={500} // Set the slide transition duration (in milliseconds)
          //  containerClass="carousel-container" // Optional: Custom CSS class for the carousel container
          //  itemClass="carousel-item" // Optional: Custom CSS class for the carousel items
           axis="horizontal" // Set the sliding direction to horizontal> 
          >
           {images.map((image, index) => (
          <img
              key={image}
              src={image}
              alt={`Slide ${index + 1}`}
               className={`group-hover:bg-white` }
             // className={`w-1/3`}
          />
          
        ))}
        {/* <div className=' flex z-10 absolute mt-10'>
          {
           names.map((name)=>(
            <> 
              <h1>{name.title}</h1>
              <p>{name.role}</p>
            </>
          ))
          }
        </div> */}
       
     </Carousel> 
     <div className='flex flex-row space-x-7'>
        
     </div>
   </div>
  </div>
  )
}

export default SectionWrapper(Team, 'team')