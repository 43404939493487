// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup,signInWithRedirect, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut} from 'firebase/auth'
import {getFirestore, doc, getDoc, setDoc, collection, writeBatch,query, getDocs} from 'firebase/firestore'

import { getDatabase, ref, get,set } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEDEwlNE6-CXsdhME6FRhdxke3HF5gU7A",
  authDomain: "gekonge-project.firebaseapp.com",
  projectId: "gekonge-project",
  storageBucket: "gekonge-project.appspot.com",
  messagingSenderId: "853191641615",
  appId: "1:853191641615:web:49a91ec8568fd6be53f17a"
};



// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);




export const db= getFirestore();

const db2 = getDatabase(firebaseApp, "https://gekonge-project-default-rtdb.firebaseio.com/");


export const getDescriptions = async ()=>{

     const collectionRef= collection(db, 'Topics')
     const q = query(collectionRef)
     
     const querySnapshot = await getDocs(q)
   
   
    
    const topics= querySnapshot.docs.reduce((acc, docSnapShot)=>{
      const {list}= docSnapShot.data()
         acc[list]=list
   
     
       return acc
    },{})

   

    return topics
 }
 
 
 export const getParagraphs = async ()=>{

  const collectionRef= collection(db, 'Topics')
  const q = query(collectionRef)
  
  const querySnapshot = await getDocs(q)
  
  let topics2=[]

 const p= querySnapshot.docs.reduce((acc, docSnapShot)=>{
   const {paragraph}= docSnapShot.data()
    topics2.push(paragraph)
   
   
 },{})


 return topics2
}



export const getImages = async ()=>{

  const collectionRef= collection(db, 'Topics')
  const q = query(collectionRef)
  
  const querySnapshot = await getDocs(q)
  
  let images=[]

 const p= querySnapshot.docs.reduce((acc, docSnapShot)=>{
   const {imageUrl}= docSnapShot.data()
    images.push(imageUrl)
   
   
 },{})


 return images
}


export const getTexts = async ()=>{

  const collectionRef= collection(db, 'Topics')
  const q = query(collectionRef)
  
  const querySnapshot = await getDocs(q)
  
  let texts=[]

 const p= querySnapshot.docs.reduce((acc, docSnapShot)=>{
   const {title}= docSnapShot.data()
    texts.push(title)
   
   
 },{})

 return texts
}




export const getBlogs = async () => {
  try {
      const snapshot = await get(ref(db2, 'blog/'));
      if (snapshot.exists()) {
        console.log(snapshot.val())
          return snapshot.val();
      } else {
          console.log("No data available");
          return null;
      }
  } catch (error) {
      console.error("Error reading data:", error);
      return null;
  }

  
};
