import React from 'react'
import Contact from './Contact'

const MainContact = () => {
  return (
    <div className='bg-yellow-800 h-128 mt-16'>
      <img
      style={{ backgroundImage:'url(../law.jpg)', backgroundAttachment:'fixed', backgroundPosition:'center', backgroundSize: 'cover',
      width: '100%',
      objectFit:'cover',
      height: '80%',
      position:'absolute'
   }}
      />
      <Contact />
    </div>
  )
}

export default MainContact