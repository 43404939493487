import React from 'react'

const CustomButton = ({title, onClick}) => {
  return (
    <div
    className="bg-transparent  hover:bg-yellow-800  py-3 px-8 font-medium inline-block mr-4 border-yellow-700  hover:text-white text-yellow-700  duration-300
    hover:border-transparent border text-center font-custom w-1/2 shadow-lg cursor-pointer  " onClick={onClick}>
      <div className='flex flex-col items-center'>
        {title}
      </div>
    </div>
  )
}

export default CustomButton