import React,{useState,useEffect, useContext} from 'react'
import NavbarMain from '../components/NavbarMain'
import { BlogImage,Blog2,Blog3,Blog4,Blog5,Blog6,Blog7,Blog8 } from '../assets'
import Footer from '../components/Footer'
import Loader2 from '../hoc/Loader2'
import { useCarouselContext } from '../context'
import PDFViewer from '../components/PDFViewer'


// const blogs = [
//   {
//    id:1,
//    topic:'Estate Planning Essentials',
//    desc:'Dive into the importance of estate planning, wills, and trusts. Discuss common misconceptions, potential pitfalls, and the benefits of proper estate planning.',
//    image:Blog2
//   },
//   {
//     id:2,
//     topic:'Navigating Family Law Issues',
//     desc:'Explore topics related to family law, including divorce, child custody, spousal support, and adoption. Provide insights on legal processes and considerations for individuals facing these situations.',
//     image:Blog3
//   },
//   {
//     id:3,
//     topic:'Business Legal Compliance',
//     desc:'Offer guidance on ensuring legal compliance for businesses, covering topics such as contracts, employment law, intellectual property protection, and regulatory compliance.',
//     image:Blog4
//   },
//   {
//     id:4,
//     topic:'Cybersecurity and Data Privacy',
//     desc:'Discuss the legal aspects of cybersecurity and data privacy, including compliance with data protection laws, handling data breaches, and protecting sensitive information.',
//     image:Blog5
//    },
//    {
//      id:5,
//      topic:'Immigration Updates and Advice',
//      desc:'Stay current with immigration law changes and provide advice for individuals and businesses navigating immigration processes. Discuss visa options, green card applications, and changes in immigration policies.',
//      image:Blog6
//    },
//    {
//      id:6,
//      topic:'',
//      image:Blog7
//    },
//    {
//     id:7,
//     topic:'',
//     image:Blog8
//   }
// ]

const BlogPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {blogs} = useCarouselContext();
  const [selectedBlog, setSelectedBlog] = useState(null);
 

    useEffect(() => {
        // Simulating a data loading delay
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }, []);


  const dataArray = Object.entries(blogs).flatMap(([key, value]) => [key, value]);
  const filteredData = dataArray.filter(item => typeof item === 'object' && item !== null);
 // console.log(filteredData)

 const readBlog = (id) => {
  const blog = filteredData.find(blog => blog.id === id);
  setSelectedBlog(blog);
};
const handleDownload = (pdf) => {
  const url = `${pdf}`;
  const link = document.createElement('a');
  link.href = url;
  link.download = 'filename.pdf';
  link.click();
};

  return (
    <>
    { isLoading? <Loader2/> :
    <div>
      <div className='pt-24'>
        <NavbarMain/>
      </div>
      <div className='w-full h-96 relative' style={{backgroundImage:"url('../law.jpg')", backgroundAttachment:'fixed', backgroundPosition:'center', background:'cover'}}>
         
      </div>
      <div className='w-full flex justify-center items-center'>
          <h1 className='font-custom sm:text-4xl text-2xl text-center text-yellow-800 py-8'>News & Insights</h1>
      </div>
      <section className='w-full h-auto flex sm:flex-row flex-col sm:px-36 px-4 sm:py-12 p-4 pt-8'>
           <div className='sm:w-2/3 h-full'>
           {selectedBlog === null ? filteredData[0] && (
                <div className='w-full h-auto ' key={filteredData[0].id} >
                      {/* <PDFViewer url={filteredData[0].imageURL}/> */}
                     <embed  src={`${filteredData[0].imageURL}#toolbar=0`} width="800px" height="1000px" type="application/pdf"/>
                   {/* <embed src={filteredData[0].imageURL} alt="blog" className="w-full sm:h-[500px] h-64" /> */}
                    <h1 className='font-custom text-yellow-800 text-2xl py-4 underline underline-offset-2'>{filteredData[0].title}</h1>
                    <button onClick={()=>handleDownload(filteredData[0].imageURL)}>Download PDF</button>
                   {/* <p>{filteredData[0].content}</p> */}
                 </div>
              ): selectedBlog && (
                <>
                    {/* <PDFViewer url={selectedBlog.imageURL}/> */}
                   <embed  src={`${selectedBlog.imageURL}#toolbar=0`} width="800px" height="1000px" type="application/pdf"/>
                  {/* <img src={selectedBlog.imageURL} alt="blog" className="w-full sm:h-[500px] h-64 " /> */}
                  <h1 className="font-custom text-2xl text-yellow-800 py-4 underline underline-offset-2">{selectedBlog.title}</h1>
                  <button onClick={()=>handleDownload(selectedBlog.imageURL)}>Download PDF</button>
                  {/* <p className="font-secondary">{selectedBlog.content}</p> */}
                </>
                 )
              }  
           </div>
           <div className='sm:w-1/3 sm:p-8 p-2'>
            <h1 className='text-yellow-800'>Recent Posts</h1>
             <ul>
               {filteredData.map((blog)=>(
                  <div className='w-full h-auto underline underline-offset-2  hover:no-underline cursor-pointer hover:bg-yellow-800 p-4' key={blog.id} onClick={()=>readBlog(blog.id)}>
                     <h1 className='font-custom'>{blog.title}</h1>
                   {/* <p className='font-secondary'>{item.content}</p> */}
                 </div>
                 ))
              }
             </ul> 
           </div>
      </section>

      <Footer/>
    </div>
      }
   </>
  )
}

export default BlogPage