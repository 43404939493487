import React from 'react'
import { prof,prof2, prof3, prof4 } from '../assets'
import NavbarMain from '../components/NavbarMain'
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Carousel from 'react-multi-carousel'
import Prof from './Prof';


const datas = [

  {
   id:1,
   details:[ {
   img:prof,
   name:'Momanyi E. Gekonge',
   title:'Managing Partner',
   summary: "Momanyi E. Gekonge is a seasoned advocate known for his strategic approach to complex legal matters. With over eight years of legal experience, he has successfully represented numerous clients, earning a reputation for legal prowess and client-focused advocacy. He is no stranger to the intensity of the courtroom, having successfully represented clients in numerous high-stakes trials. He has demonstrated an exceptional ability to navigate the intricacies of the legal system, securing favorable verdicts in a spectrum of cases, ranging from commercial to civil disputes. Adept at crafting compelling legal arguments and anticipating opposing strategies, Momanyi brings a dynamic and assertive presence to the courtroom. He is committed to ensuring that clients not only receive expert legal representation but also feel supported throughout the legal process.",
   expertise:[
    "Dispute Resolution",
    "Oil and gas law",
    "Power and Renewables",
    "Commercial and Corporate Law",
    "Mining Law"
   ]
   }
]
  },
  {
    id:2,
    details:[ {
   img:prof2,
   name:'Arnold Mugogo',
   title:'Partner and Head of Litigation Department',
   summary: "Arnold brings a wealth of expertise in commercial litigation. He is a dedicated and detail-oriented advocate. His strong analytical skills and commitment to achieving favorable outcomes makes Arnold an invaluable asset to our team. Known for his meticulous attention to detail and thorough understanding of commercial law, He crafts tailored legal strategies to address the unique challenges presented by each case. He has successfully represented clients in diverse courts, arbitration proceedings, and alternative dispute resolution forums. Whether it's resolving breach of contract issues, shareholder disputes, or intellectual property conflicts, Arnold possesses the skills and acumen necessary to navigate the intricate landscape of commercial litigation. His proactive approach to case management and ability to adapt to evolving legal dynamics have contributed to his consistent success in achieving favorable resolutions.",
   expertise:[
    'Contract Disputes',
    'Intellectual Property Litigation',
    'Shareholder and Partnership Disputes',
    'Employment Litigation',
    'Environment and Land Litigation'
   ]
}
]
  },
  {
    id:3,
    details:[ {
    img:prof3,
    name:'Brian Mogaka',
    title:'Partner and Head of Corporate and Real Estate Department', 
    summary: "Brian boasts a distinguished career in corporate law, providing invaluable counsel to businesses of all sizes. Specializing in transactional matters, he has guided clients through mergers and acquisitions, corporate governance, contract negotiations, and regulatory compliance. Known for his meticulous attention to detail, Brian ensures that businesses navigate the complex legal landscape with confidence. His proactive approach in identifying and mitigating potential legal risks has contributed to the success and growth of numerous enterprises under his representation. In the realm of real estate law, Brian is recognized for his proficiency in handling diverse transactions. From residential and commercial real estate acquisitions to leasing agreements and property development, he has provided comprehensive legal support to individuals, investors, and developers. He is adept at navigating the intricacies of real estate law, ensuring that clients' interests are protected throughout every stage of a transaction. Whether facilitating smooth closings or resolving complex title issues, he is committed to delivering efficient and effective legal solutions in the ever-evolving real estate landscape.",
    expertise:[
        'Property Law',
        'Banking and Finance',
        'Commercial and Residential Real Estate',
        'Land Use and Zoning',
        'Construction & Infrastructure'
    ]
  }
]
  },
  {
    id:4,
    details:[ {
    img:prof4,
    name:'Mugendi Gachuba',
    title:'Partner and Head of Commercial and Tax Department', 
    summary: "Mugendi Gachubah is a distinguished legal professional specializing in Public Finance and Corporate Law. With an unwavering commitment to excellence and a comprehensive understanding of the intricate legal landscape, Mugendi has built a stellar reputation as a trusted advisor to clients navigating complex private and public financial and corporate matters. His career has been marked by a series of achievements and milestones. He possesses a comprehensive understanding of public finance, encompassing procurement laws, tax and regulatory compliance. His expertise extends to corporate law, where he has advised clients on competition, mergers and acquisitions, corporate governance, and contractual matters. His unique skill set positions him as a versatile advocate capable of seamlessly bridging the gap between public and private sector legal requirements.Mugendi stands as a beacon of excellence in the legal field, combining his passion for law and finance to deliver unparalleled legal counsel. With a proven track record of success and a commitment to ethical practice, Mugendi continues to be a driving force in shaping the legal landscape of public finance and corporate law. Apart from being an astute advocate, he is the most dapper one, always clad in suits from “Saville Row”.",
    expertise:[
        'Public Finance',
        'Corporate Law',
        'Procurement Law',
        'Tax law',
      
    ]
  }
]
  }
]

const Profile = () => {
    let { id } = useParams();
    const profile = datas[id]
    console.log('profile',profile)
  return (
    <div>
        <div className='pt-24'>
            <NavbarMain/>
        </div>
        <Prof profile={profile} id={id}/>
      <Footer/>
    </div>
  )
}

export default Profile