import React, { useRef, useState} from 'react'
import SectionWrapper from '../../hoc/SectionWrapper'
import {logo , prof} from '../../assets'
import {motion} from 'framer-motion'
import {slideIn} from '../../utils/utils'
import {ImOffice} from 'react-icons/im'
import {AiOutlineMail} from 'react-icons/ai'
import {BsTelephoneInboundFill} from 'react-icons/bs'
import {BsFillClockFill} from 'react-icons/bs'
import CustomButton from '../../components/CustomButton'
import { Logo2 } from '../../assets'
import emailjs from '@emailjs/browser'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const Contact2 = () => {
  const navigate = useNavigate()
 const formRef= useRef()
 const[loading, setLoading]=useState(false)
 const [selectedDate, setSelectedDate] = useState(null);
 const [form,setForm]=useState({
  name:'',
  email:'',
  date:selectedDate,
  time:'',
  message:''
 })


 const handleChange =(e)=>{
  const{name, value}= e.target;
  //console.log(name)
  setForm({...form, [name]:value})
  console.log(form, 'form')
}
 const handleSubmit =(e)=>{
  e.preventDefault();
  setLoading(true)

  emailjs.send(
  'service_5sswurv', 
  'template_wurshw9',
  { 
    appointment:`Appointment`,
    from_name: form.name,
    to_name:'Edwin',
    from_email:form.email,
    date:`${selectedDate}`,
    time : `${form.time}`,
    message: form.message
  },
  '2nMcMXO5DznobaeXC'
  )
 .then(()=>{
    setLoading(false);
    toast.success('Thank you. I will get back to you as soon as possible.')
     navigate('/')
    setForm({
      name:'',
      email:'',
      date:null,
      time:'',
      message:''
    })

  }, (error) =>{
   setLoading(false)
   console.log(error)

   toast.error('Something went wrong')
  })

}

  return (
    <div className=' w-full bg-black  md:h-[590px] h-[670px] mt-0 shadow-lg' >
      <div className='h-[780px] w-1/3 absolute z-10'>

      </div>
    <div className='md:w-1/2 w-full md:left-40 left-2 absolute z-3'>
     <motion.div
       variants={slideIn('left', 'tween', 0.3, 1)}
       className="flex-[0.75] bg-black-100 p-8 rounded-2xl "
      >
         
        {/* <h1 className='text-yellow-600 flex mt-12'>CONTACT FORM</h1> */}
        <h1 className='font-custom text-white text-5xl text-left py-2'> Make an Appointment</h1>
        {/* <p className='text-white font-custom text-left  py-2'>
          Submit your case today for a free quote on our consultancy fees. Let our experienced team navigate the complexities and ensure 
           the best possible outcome for your legal matter</p> */}
        <form
         ref={formRef}
         onSubmit={()=>{}}
         className="mt-6 flex flex-col md:gap-8 gap-4"
        >
          <div className='flex md:flex-row flex-col md:space-x-8 space-x-0 md:space-y-0 space-y-8 py-0'>
            <label className='flex flex-col sm:w-1/2 z-20 '>
     
              <input
               type='text'
               value={form.name}
               placeholder="Name?"
               onChange={handleChange}
               name='name'
               className="border-yellow-800  border bg-transparent py-3 md:px-6 px-12
               placeholder:text-secondary
               text-white
               placeholder:text-yellow-800
             font-medium"
             
                />
            </label>
            <label className='flex flex-col  sm:w-1/2  px-0 z-10 '>

              <input
               type='text'
                value={form.email}
                placeholder="Email?"
                name='email'
                onChange={handleChange}
                className="border-yellow-800  border bg-transparent py-3 md:px-6 px-12
               placeholder:text-secondary
               text-white
               placeholder:text-yellow-800
               
             font-medium"
              />
            </label>
           </div>
           <div className='flex md:flex-row flex-col md:space-x-8 space-x-0 md:space-y-0 space-y-8'>
            <label className='flex flex-col sm:w-1/2 px-0 z-20 '>
            <DatePicker
              type='date'
              value={selectedDate}
              placeholder="Date?"
              name='date'
              selected={selectedDate}
               onChange={date => setSelectedDate(date)}
               minDate={new Date()} // Set a minimum selectable date
               maxDate={new Date('2023-12-31')} // Set a maximum selectable date
               showYearDropdown
               placeholderText="Select a date"
               scrollableYearDropdown
               className="border-yellow-800 z-20 w-full border bg-transparent py-3 md:px-6 px-12
               placeholder:text-secondary
               text-white
               placeholder:text-yellow-800
             font-medium"
       
               />
            </label>
            <label className='flex flex-col sm:w-1/2 px-0 z-10 '>
             
              <input
               type='text'
               value={form.time}
                placeholder="Time Interval?"
                onChange={handleChange}
                name='time'
                className="border-yellow-800  border bg-transparent py-3 md:px-6 px-12
                placeholder:text-secondary
                text-white
                placeholder:text-yellow-800
              font-medium"
              />
            </label>
           </div>
           <label className='flex md:flex-col flex-row z-10 '>
          
             <textarea
              rows='4'
              type='text'
             value={form.message}
              placeholder="Message?"
              onChange={handleChange}
              name='message'
              className="border-yellow-800  border bg-transparent sm:py-3 md:px-6 px-12
              placeholder:text-secondary
              text-white
              placeholder:text-yellow-800
            font-medium"
             
             >
             </textarea>
           </label>
           <div className='md:px-0 px-0 z-10 '>
           <CustomButton
            title={loading? 'Booking...' : 'Book an Appointment'}
             onClick={handleSubmit}
           />
          </div>
        </form>

        
        
      </motion.div>
      
      </div>
      
    </div>
  )
}

export default Contact2