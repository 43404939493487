import React,{useEffect} from 'react'
import Contact2 from './Contact 2'
import PageImg from '../../components/PageImg'
import Footer2 from './Footer2'
import NavbarMain from '../../components/NavbarMain'
import { useParams} from 'react-router-dom'
import {Helmet} from 'react-helmet-async'

const Appointment = () => {

  const {pathName}= useParams();
  
  useEffect(()=>{
   window.scrollTo(0, 0)
  },[pathName])

  return (
    <>
    <Helmet>
      <title>Appointment</title>
      <meta name="description" content="Book an appointment for Consultation"/>
      <link rel='canonical' href='/Appointment'/>
    </Helmet>
    <div className='mt-24'>
        
      <NavbarMain/>  
      <PageImg/>
      <Contact2/>
     
      <Footer2/>
      

      
    </div>
    </>
  )
}

export default Appointment