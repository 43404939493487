import React from 'react'
import Profile from './Profile'
import { Routes,Route } from 'react-router-dom'
import Partners from './Partners'

const Cv = () => {
  return (
  
    <Routes>
       <Route index   element={<Partners/>}/>
       <Route path=":id" element={<Profile/>}/>
    </Routes>
 
  )
}

export default Cv