import React,{useState} from 'react'
import SectionWrapper from '../hoc/SectionWrapper'
import {logo , prof} from '../assets'
import { motion, Variants } from "framer-motion";
import Experts from './Team';
import {AiOutlineHome} from 'react-icons/ai'
import { fadeIn } from '../utils/utils';

const cardVariants: Variants = {
  offscreen: {
    x: 315,
  
  },
  onscreen: {
    x: 0,
    
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.9
    }
  }
};


const importAll=(r)=>
  r.keys().reduce((acc,item)=>{
    acc[item.replace("./", "")] = r(item);
    return acc
  }, {})

 const heroTextureImports = importAll(
    require.context("../assets/profs", false, /\.(png|jpe?g|svg|webp)$/)
)

const About = () => {
  const images= Object.values(heroTextureImports);
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const previousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  return (
    <motion.div variants={fadeIn("up", "spring", 0.3 * 0.5, 0.4)} className='bg-white left-2  absolute sm:h-screen h-[1100px] w-full md:-mt-6 mt-12 opacity-50  shadow-xl ' >
      <h1 className='font-custom sm:text-5xl text-3xl text-left px-8 text-yellow-800'>Overview <br/> of the Firm</h1>
      <div className='grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2 sm:p-8 p-6'>
        
        <div className='flex flex-col sm:space-y-5 text-left font-custom   '>
           <div>Gekonge Momanyi &amp; Associates is an established professional and innovative law firm. We are
            more than just a legal practice; we are your partners in navigating the complex landscape of law
           with precision, passion, and purpose. Founded on a bedrock of unwavering commitment to
             justice, our firm has become synonymous with excellence in legal representation.</div>
           <div>
           We employ our internal resources fully and engage effectively with external resources to deliver
            professional legal services and representation. We also ensure proficient use of technology to
            provide convenience to our esteemed clients.<br/>
          Our distinguished team of attorneys boasts a collective wealth of knowledge and experience
          that spans decades. With a proven track record of delivering unparalleled results, we have
           earned the trust of clients from all walks of life and industries. Whether you are an individual
           seeking justice, a startup striving for success, or a multinational corporation facing intricate legal
           challenges, we are your unwavering advocates. The Team has extensive knowledge, resources
           and skills in several practice areas, including, commercial law; property law and conveyance
            practice; environmental law; tax law; intellectual property law; procurement law; employment
             and labour law; succession law; family law; arbitration; and civil litigation.<br/>
              <br/>   What sets us apart is not just our extensive legal acumen, but our genuine dedication to the
              people and organizations we serve. We take pride in listening to your unique needs,
              understanding your objectives, and crafting innovative, tailored legal solutions that surpass
              expectations. 
           </div>
        </div>
        <div className='flex flex-col space-y-5 text-left font-custom  '>
          <div>
          We focus on building cordial and durable relationships with our clients. We take a
          simple yet effective approach of first seeking to know the client and comprehensively
          understanding their requirements, then we work closely with the client to generate robust
          solutions. The execution is precise and efficient and the client is promptly updated at all times.
          Ensuring convenience to the client is a top consideration in the service delivery.<br/>
          <br/>Our long-term vision is to be a trusted and leading law firm in Africa and the most innovative on
          the Continent. We have a documented strategy to back this vision and a dedicated team. We
          constantly invest our time and efforts in developing and renewing our knowledge base to enable
          us provide unique insights to our clients and to deliver brilliant solutions all the time. Our
          commitment to staying at the forefront of legal developments ensures that you receive cutting-
          edge counsel, fortified by time-honored principles.<br/>
          <br/>But it&#39;s not just about the cases we win or the deals we close. We measure our success by the
          trust we inspire and the positive impact we make on the lives of our clients. Our unwavering
          integrity, tireless work ethic, and relentless pursuit of justice have earned us the respect of peers
          and the gratitude of countless clients.<br/>
          </div>
          <div>
           We are not just legal advisors; we are your strategic partners in achieving your goals,
           safeguarding your interests, and ensuring a brighter, more secure future.
           Choose Gekonge Momanyi &amp; Associates for a legal experience that transcends expectations.
           Your story is our mission, and your success is our victory. Discover the difference with Gekonge
           Momanyi &amp; Associates—Where Law Meets Excellence.
          </div>
        </div>
      </div>
      <div className='w-full sm:h-56 h-[550px]  bg-yellow-800 flex sm:flex-row flex-col  justify-between relative sm:-mt-4 mt-0 font-custom text-xl '>
         <div className='flex flex-col sm:text-left text-center sm:p-6 p-0 sm:pt-0 pt-12 text-black  '>
          <>
            <h1 className='font-semibold '> Our Vision</h1>
            <p>To be the most trusted and innovative law Firm <br/> on the African Continent.</p>
          </>
           <>
            <h1 className='font-semibold '>Our Mission</h1>
            <p>To provide our esteemed clients with robust<br/> legal solutions through creativity, resourceful<br/>ness and use of modern technology.</p>
           </>
         </div>
         <div className='flex flex-col sm:text-left text-center  sm:p-6 p-0 sm:pb-0 pb-12 '>
            <h1 className='font-semibold '>Our Core values</h1>
             <p>The Firm and each Member have adopted the following as their guiding values.</p>
             <ul>
              <li><span></span>&#9679; Excellence</li>
              <li><span></span>&#9679; Integrity </li>
              <li><span></span>&#9679; Teamwork</li>
              <li><span></span>&#9679; Good corporate citizenship</li>
             </ul>
        <div>

        </div>
         </div> 
      </div>
     
    </motion.div>
  )
}

export default SectionWrapper(About, 'about')