import React,{useState, useEffect} from 'react'
import {Link }from 'react-router-dom'
import {Helmet} from 'react-helmet-async'
import Loader2 from '../hoc/Loader2';
import NavbarMain from '../components/NavbarMain';
import Footer from '../components/Footer';
import Display from '../components/Display';
import Services from '../components/Services';
import Display2 from '../components/Display2';
import MiniCarousel from '../components/MiniCarousel';
import { prof,prof2, prof3 } from '../assets';
import Partners from './Partners';
import Cv from './Cv';





const Firm = () => {
    const [isLoading, setIsLoading] = useState(true);
    const firm = 'firm/2'

    useEffect(() => {
        // Simulating a data loading delay
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }, []);

     
  return (
    <>
    <Helmet>
      <title>GEKONGE MOMANYI AND ASSOCIATES</title>
      <meta name="description" content="Gekonge momanyi and associates is a law firm with the most experienced lawyers in various law fields"/>
      <link rel='canonical' href='/'/>
    </Helmet>
    { isLoading? <Loader2/> :
    <div className='bg-white ' >
      <NavbarMain/> 
      <div className='w-full md:h-[400px] flex flex-col justify-end h-[300px] mt-24' style={{backgroundImage:"url('../Firm.jpg')", backgroundAttachment:'fixed', backgroundPosition:'center', background:'cover'}}>
        {/* <h1 className='font-primary font-bold sm:text-4xl text-xl text-left px-8 text-yellow-800 p-4'>Overview <br/> of the Firm</h1> */}
      </div>
      <div className='flex flex-col justify-center items-center py-12 '>
        <h1 className='font-custom text-4xl'>OUR FIRM</h1>
      </div>
      <div className='flex flex-col mb-4'>
        <div className='  w-full md:-mt-6 -mt-16 lg:px-48 px-8 md:py-0'>
        <h1 className='font-custom text-yellow-800 text-2xl py-4'>Our Brand</h1>
       <p className='font-secondary border-l-2 border-yellow-800 px-4'>Gekonge Momanyi & Associates is an established professional and innovative law firm. We are more than just a legal practice; we are your partners in navigating the complex landscape of law with precision, passion, and purpose.
         Founded on a bedrock of unwavering commitment to justice, our firm has become synonymous with excellence in legal representation. We employ our internal resources fully and engage effectively with external resources to deliver 
         professional legal services and representation.<br/><span className='ml-4'> With a proven track record of delivering unparalleled results, we have earned the trust of clients from all walks of life and industries. Whether you are an individual seeking justice, a startup striving for success, or a multinational corporation facing intricate legal challenges, we are your unwavering advocates. </span>
       </p>
       <h1 className='font-custom text-yellow-800 text-2xl py-4 '>Our Services </h1>
       <p className='font-secondary border-l-2 border-yellow-800 px-4'>Our distinguished team of attorneys boasts a collective wealth of knowledge and experience that spans decades. The Team has extensive knowledge, resources and skills in several practice areas, including, commercial law; property law and conveyance practice; environmental law; 
        tax law; intellectual property law; procurement law; employment and labour law; succession law; family law and dispute resolution. 
        <br/><span className='ml-4'>What sets us apart is not just our extensive legal acumen, but our genuine dedication to the people and organizations we serve. We take pride in listening to your unique needs, understanding your objectives, and crafting innovative, tailored legal solutions that surpass expectations.</span>
       </p>
       <h1 className='font-custom text-yellow-800 text-2xl py-4'>Relationship with our clients</h1>
        <p className='font-secondary border-l-2 border-yellow-800 px-4'>
        We focus on building cordial and durable relationships with our clients. We take a simple yet effective approach of first seeking to know the client and comprehensively understanding their requirements 
        to generate robust solutions. Our execution is precise and efficient. Ensuring convenience to the client is a top consideration in the service delivery. it's not just about the cases we win or the deals
         we close.<br/><span className='ml-4'>We measure our success by the trust we inspire and the positive impact we make on the lives of our clients. Our unwavering integrity, tireless work ethic, and relentless pursuit of justice have earned us the respect of peers and the gratitude of countless clients.</span> 
        </p>
     
        </div>
     
      


      </div>
      {/* <div className='p-4'>
        <Cv/>
      </div> */}
      
      <Footer/>
    </div>  
      }
    </>
  )
}

export default Firm