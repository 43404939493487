import React,{useRef, useState,useEffect} from 'react'
import NavbarMain from '../components/NavbarMain'
import PageImg2 from '../components/PageImg2'
import Contact from '../components/Contact'
import emailjs from '@emailjs/browser'
import {toast }from 'react-hot-toast'
import { useCarouselContext } from '../context'
import CustomButton from '../components/CustomButton'
import Footer from '../components/Footer'
import { Britam2 } from '../assets'
import Loader2 from '../hoc/Loader2'


const ContactPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const formRef= useRef()
 const[form,setForm]=useState({
  name:'',
  email:'',
  phone: '',
  subject:'',
  message:''
 })
 const[loading, setLoading]=useState(false)
 const{contactsSectionRef}=useCarouselContext()


 const handleChange =(e)=>{
  const{name, value}= e.target;
  //console.log(name)
  setForm({...form, [name]:value})
  console.log(form, 'form')
}

 const handleSubmit =(e)=>{
  e.preventDefault();
  setLoading(true)

  emailjs.send(
  'service_5sswurv', 
  'template_0u18l8r',
  {
    from_name: form.name,
    to_name:'Edwin',
    from_email:form.email,
    phone:`${form.phone}`,
    text:`${form.subject}`,
    message: form.message,
  },
  '2nMcMXO5DznobaeXC'
  )
  .then(()=>{
    setLoading(false);
    toast.success('Thank you. I will get back to you as soon as possible.')

    setForm({
      name:'',
      email:'',
      phone: '',
      subject:'',
      message:''
    })

  }, (error) =>{
   setLoading(false)
   console.log(error)

   toast.error('Something went wrong')
  })

 

}
useEffect(() => {
  // Simulating a data loading delay
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);
}, []);
  return (
    <>
    { isLoading? <Loader2/> :
    <div>
      <div className='pt-24'>
        <NavbarMain/>   
      </div>
      <div className='w-full h-[500px]' style={{backgroundImage:"url('../britam.jpg')", backgroundAttachment:'fixed', backgroundPosition:'center', background:'cover', backgroundRepeat:'no-repeat'}}>
      
    
      </div>
      <div className='sm:p-16 p-8 w-full flex sm:flex-row flex-col justify-center items-center'>
        <div className='w-3/4  sm:h-[650px]  h-[840px]' ref={contactsSectionRef} id="contacts" >
           <div className='w-full z-3'>
            <div className="flex-[0.75] bg-black-100  rounded-2xl " >
             <h1 className='text-yellow-800 flex'>CONTACT FORM</h1>
             <h1 className='font-custom text-yellow-800 sm:text-5xl text-2xl text-left py-2'> Need Consultancy, request a Free Quote</h1>
              <p className='text-black font-custom text-left w-3/4  py-2'>
             Submit your case today for a free quote on our consultancy fees. Let our experienced team navigate the complexities and ensure 
             the best possible outcome for your legal matter</p>
             <form
             ref={formRef}
             onSubmit={()=>{}}
             className="sm:mt-6 flex flex-col gap-8 "
               >
              <div className='flex sm:flex-row flex-col sm:space-x-8 sm:space-y-0 space-y-4 py-0'>
               <label className='flex flex-col sm:w-1/3 w-full px-0 '>
     
                <input
               type='text'
               value={form.name}
               name='name'
               placeholder="Name?"
               onChange={handleChange}
               className="border-yellow-800  border bg-transparent py-3 px-6
               placeholder:text-secondary
               text-white
               placeholder:text-yellow-800
             font-medium"
                />
            </label>
            <label className='flex flex-col sm:w-1/3 w-full px-0'>
              <input
               type='text'
                value={form.email}
                name='email'
                placeholder="Email?"
                onChange={handleChange}
                className="border-yellow-800  border bg-transparent py-3 px-6
               placeholder:text-secondary
               text-white
               placeholder:text-yellow-800
             font-medium"
              />
            </label>
           </div>
           <div className='flex sm:flex-row flex-col sm:space-x-8 sm:space-y-0 space-y-4 py-0'>
            <label className='flex flex-col sm:w-1/3 w-full px-0'>
            
              <input
               type='text'
              value={form.phone}
              name='phone'
               placeholder="Phone?"
               onChange={handleChange}
               className="border-yellow-800  border bg-transparent py-3 px-6
               placeholder:text-secondary
               text-white
               placeholder:text-yellow-800
             font-medium"
             
                />
            </label>
            <label className='flex flex-col sm:w-1/3 w-full px-0'>
             
              <input
               type='text'
               value={form.subject}
                placeholder="Subject?"
                name='subject'
                onChange={handleChange}
                className="border-yellow-800  border bg-transparent py-3 px-6
                placeholder:text-secondary
                text-white
                placeholder:text-yellow-800
              font-medium"
              />
            </label>
           </div>
           <label className='flex sm:flex-col flex-row'>
          
             <textarea
              rows='4'
              type='text'
              name='message'
              value={form.message}
              placeholder="Message?"
              onChange={handleChange}
              className="border-yellow-800 sm:w-3/4 w-full  border bg-transparent py-3 px-5
              placeholder:text-secondary
              text-white
              placeholder:text-yellow-800
            font-medium"
             
             >
             </textarea>
           </label>
           <div className='sm:w-1/2 w-full '>
             <CustomButton
              title={loading? 'Submitting...' : 'Submit it now'}
              onClick={handleSubmit}
             />
           </div>
           
          
        </form>

 
        
      </div>
      
           </div>

     
         </div>
        
      </div>
      <div className='flex sm:flex-row flex-col w-full'>
        <div className='sm:w-1/2 shadow-xl'>
         <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=britam%20towers,%20Upperhill%20Nairobi+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Calculate population in area</a></iframe>
       </div>
       <div className='sm:w-1/2 relative  '>
         <img src={Britam2} alt="" className='w-full h-[600px] shadow-xl' />
           <div className=' absolute w-96 h-96 bg-white shadow-xl bottom-0 right-0  rounded-tl-full'>
            <div className='w-full h-full flex flex-col justify-center align-center items-center '>
               <h1 className='font-custom pt-3'>Our Location</h1>
               <h1 className='text-3xl text-yellow-800  font-custom'>Britam Towers</h1>
               <h1 className='font-secondary py-1'>24th Floor, Regus, Suite 2b</h1>
               <h1 className='font-secondary py-1' >P.o Box 59091-00100</h1>
               <h1 className='font-secondary py-1'>Nairobi</h1>
                <h1 className='font-secondary py-1'>Office Line: +254 709 385 074</h1>
                <h1 className='font-secondary py-1'>Email: gekonge@gekongemomanyi.co.ke</h1>
             </div>
           </div>
       </div>
      </div>
      <Footer/>
    </div>
      }
   </>
  )
}

export default ContactPage  