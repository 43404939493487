import React,{useState, useEffect} from 'react';
import { Logo2 } from '../assets';
//import './Loader.css'; // You can create a CSS file for styling the loader
import { motion, AnimatePresence } from 'framer-motion';

const initialGradient = {
  from: 'linear-gradient(45deg, #FF6B6B, #556270)',
  to: 'linear-gradient(45deg, #6A3093, #A044FF)'
};

const targetGradient = {
  from: 'linear-gradient(45deg, #5F72BD, #9B23EA)',
  to: 'linear-gradient(45deg, #A044FF, #FC7272)'
};
const Loader2 = () => {
  const [isTargetGradient, setIsTargetGradient] = useState(false);

  useEffect(() => {
    // Simulating a data loading delay
    setTimeout(() => {
      setIsTargetGradient(prevState => !prevState);
    }, 1000);
  }, []);
 
  return (
    // <div className="loader-container">
     
    //  <div className="loader2">
    //   </div> 
    //      {/* <img
    //       src={Logo2}
    //       alt='logo'
    //        className={`lg:w-[200px] w-[130px] h-[95px] absolute sm:left-16  left-8 object-contain cursor-pointer sm:mt-5 mt-7 text-4xl z-50`}
    //       /> */}
     
    //   <p className='text-white font-custom text-2xl'>GEKONGE MOMANYI & ADVOCATES</p>
      
    //   {/* <AnimatePresence>
    //     <motion.div
    //       //  
    //       className="gradient-box"
    //       initial={{ background: initialGradient.from }}
    //       animate={{ background: isTargetGradient ? targetGradient.from : initialGradient.from }}
    //       exit={{ background: targetGradient.to }}
    //       transition={{ duration: 1 }}
    //     >
    //       <motion.div
    //         className="gradient-overlay"
    //         initial={{ background: initialGradient.to }}
    //         animate={{ background: isTargetGradient ? targetGradient.to : initialGradient.to }}
    //         exit={{ background: targetGradient.to }}
    //         transition={{ duration: 1 }}
    //       />
    //     </motion.div>
    //   </AnimatePresence> */}
    // </div>
<div className='loader-container'>   
  <div className={`${'loader2'} ' text-yellow-800 font-custom text-4xl `}>

    <span className=' text-yellow-800 font-custom text-4xl'>GEKONGE MOMANYI </span>

  
  </div>
  <div className={ ` ${'loader4'} h-[1px] w-64 bg-yellow-800`}></div>
  <div className={`${'loader3'} `}>

     <span className='text-white font-custom text-4xl'> & ASSOCIATES LLP</span> 

  
  </div>
</div> 
  );
};

export default Loader2;
