import React, { useEffect, useState } from 'react';
import * as pdfjs from 'pdfjs-dist';

const PdfImageRenderer = ({ pdfUrl }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const renderFirstPageAsImage = async () => {
      pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.8.335/pdf.worker.min.js';
      try {
        
        // Load PDF file
        const pdf = await pdfjs.getDocument(pdfUrl).promise;

        // Get the first page
        const pageNum = 1;
        const page = await pdf.getPage(pageNum);

        // Create canvas element
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Set canvas dimensions
        const viewport = page.getViewport({ scale: 1 });
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // Render first page to canvas
        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        await page.render(renderContext).promise;

        // Convert canvas to image
        const imageData = canvas.toDataURL('image/png');

        // Set image source
        setImageSrc(imageData);
      } catch (error) {
        console.error('Error rendering PDF:', error);
      }
    };

    renderFirstPageAsImage();
  }, [pdfUrl]);

  return (
    <div>
      {imageSrc && <img className='w-full h-auto'src={imageSrc} alt="PDF First Page" />}
    </div>
  );
};

export default PdfImageRenderer;
