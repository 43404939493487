import React, { useRef, useState} from 'react'
import SectionWrapper from '../hoc/SectionWrapper'
import {logo , prof} from '../assets'
import {motion} from 'framer-motion'
import {slideIn} from '../utils/utils'
import {ImOffice} from 'react-icons/im'
import {AiOutlineMail} from 'react-icons/ai'
import {BsTelephoneInboundFill} from 'react-icons/bs'
import {BsFillClockFill} from 'react-icons/bs'
import CustomButton from './CustomButton'
import { Logo2 } from '../assets'
import { useCarouselContext } from '../context'
import emailjs from '@emailjs/browser'
import {toast }from 'react-hot-toast'


const Contact = () => {
 const formRef= useRef()
 const[form,setForm]=useState({
  name:'',
  email:'',
  phone: '',
  subject:'',
  message:''
 })
 const[loading, setLoading]=useState(false)
 const{contactsSectionRef}=useCarouselContext()


 const handleChange =(e)=>{
  const{name, value}= e.target;
  //console.log(name)
  setForm({...form, [name]:value})
  console.log(form, 'form')
}

 const handleSubmit =(e)=>{
  e.preventDefault();
  setLoading(true)

  emailjs.send(
  'service_5sswurv', 
  'template_0u18l8r',
  {
    from_name: form.name,
    to_name:'Edwin',
    from_email:form.email,
    phone:`${form.phone}`,
    text:`${form.subject}`,
    message: form.message,
  },
  '2nMcMXO5DznobaeXC'
  )
  .then(()=>{
    setLoading(false);
    toast.success('Thank you. I will get back to you as soon as possible.')

    setForm({
      name:'',
      email:'',
      phone: '',
      subject:'',
      message:''
    })

  }, (error) =>{
   setLoading(false)
   console.log(error)

   toast.error('Something went wrong')
  })

}
  return (
    <motion.div variants={slideIn('left', 'tween', 0.3, 1)} className=' w-full bg-black  sm:h-[650px]  h-[840px] mt-0 shadow-lg' ref={contactsSectionRef} id="contacts" >
    <div className='w-3/4 sm:left-28 left-12 absolute z-3'>
     <div className="flex-[0.75] bg-black-100  rounded-2xl " >
        <h1 className='text-yellow-600 flex mt-12'>CONTACT FORM</h1>
        <h1 className='font-custom text-white sm:text-5xl text-2xl text-left py-2'> Need Consultancy, request a Free Quote</h1>
        <p className='text-white font-custom text-left  py-2'>
          Submit your case today for a free quote on our consultancy fees. Let our experienced team navigate the complexities and ensure 
           the best possible outcome for your legal matter</p>
        <form
         ref={formRef}
         onSubmit={()=>{}}
         className="sm:mt-6 flex flex-col gap-8 "
        >
          <div className='flex sm:flex-row flex-col sm:space-x-8 sm:space-y-0 space-y-4 py-0'>
            <label className='flex flex-col sm:w-1/3 w-full px-0 '>
     
              <input
               type='text'
               value={form.name}
               name='name'
               placeholder="Name?"
               onChange={handleChange}
               className="border-yellow-800  border bg-transparent py-3 px-6
               placeholder:text-secondary
               text-white
               placeholder:text-yellow-800
             font-medium"
                />
            </label>
            <label className='flex flex-col sm:w-1/3 w-full px-0'>
              <input
               type='text'
                value={form.email}
                name='email'
                placeholder="Email?"
                onChange={handleChange}
                className="border-yellow-800  border bg-transparent py-3 px-6
               placeholder:text-secondary
               text-white
               placeholder:text-yellow-800
             font-medium"
              />
            </label>
           </div>
           <div className='flex sm:flex-row flex-col sm:space-x-8 sm:space-y-0 space-y-4 py-0'>
            <label className='flex flex-col sm:w-1/3 w-full px-0'>
            
              <input
               type='text'
              value={form.phone}
              name='phone'
               placeholder="Phone?"
               onChange={handleChange}
               className="border-yellow-800  border bg-transparent py-3 px-6
               placeholder:text-secondary
               text-white
               placeholder:text-yellow-800
             font-medium"
             
                />
            </label>
            <label className='flex flex-col sm:w-1/3 w-full px-0'>
             
              <input
               type='text'
               value={form.subject}
                placeholder="Subject?"
                name='subject'
                onChange={handleChange}
                className="border-yellow-800  border bg-transparent py-3 px-6
                placeholder:text-secondary
                text-white
                placeholder:text-yellow-800
              font-medium"
              />
            </label>
           </div>
           <label className='flex sm:flex-col flex-row'>
          
             <textarea
              rows='4'
              type='text'
              name='message'
              value={form.message}
              placeholder="Message?"
              onChange={handleChange}
              className="border-yellow-800 sm:w-3/4 w-full  border bg-transparent py-3 px-5
              placeholder:text-secondary
              text-white
              placeholder:text-yellow-800
            font-medium"
             
             >
             </textarea>
           </label>
           <div className='sm:w-1/2 w-full '>
             <CustomButton
              title={loading? 'Submitting...' : 'Submit it now'}
              onClick={handleSubmit}
             />
           </div>
           
          
        </form>

 
        
      </div>
      
      </div>

     
    </motion.div>
  )
}

export default SectionWrapper(Contact, 'contact')