import React,{useState} from 'react'
import SectionWrapper from '../hoc/SectionWrapper'
import {motion, Variants} from 'framer-motion'
import {Tilt} from 'react-tilt'
import { fadeIn } from '../utils/utils'
import {services} from '../constants'
import {IoBusinessSharp} from 'react-icons/io'
import ProjectCard from './ProjectCard'
import { Law } from '../assets'
import MiniCarousel from './MiniCarousel'
import MiniCarousel2 from './MiniCarousel2'
import { BriefCase } from '../assets'


const Services = ({index}) => {
 const [itsOn, setItsOn] = useState(false)
 return(
  <div className='sm:pt-2 pt-[1500px]'>
  <div className='relative bg-gradient-to-b from-black to-yellow-800 xs:mt-[350px] sm:mt-20 mt-[620px]  flex flex-col gap-2 items-center'>
   <h1 className='font-custom text-6xl text-center text-yellow-800 py-2 mt-10'>Practice Areas</h1>
   <img src={BriefCase} className='w-[60px] h-[60px] text-black'/>   
      <MiniCarousel2/>
   </div> 
  </div>
 )
}

export default SectionWrapper(Services, 'services') 