import React,{useEffect, useState, useMemo} from 'react'
import { useParams } from 'react-router-dom'
import { specialties } from '../../constants';
import Footer from '../../components/Footer';
import { useCarouselContext } from '../../context';
import firebase from "firebase/app";
import SectionWrapper from '../../hoc/SectionWrapper';
import Content from './Content';
import Loader from '../../hoc/Loader';
import Loader2 from '../../hoc/Loader2';
import {RxCross1} from 'react-icons/rx'
import Footer3 from './Footer3'
import NavbarMain3 from './NavbarMain3';
import {Helmet} from 'react-helmet-async'


const TopicDetail = () => {
  <Helmet>
      <title>Practice Areas</title>
      <meta name="description" content="Our areas of Legal Counsel"/>
      <link rel='canonical' href='/Practice Areas'/>
  </Helmet>
    const {id} = useParams();
    console.log(id)
    const {topics, topics2, images, texts} = useCarouselContext()
    const [isLoading, setIsLoading] = useState(true);
    // const topic = specialties.find(topic => topic.title === id);
     const mainTopic=Object.values(topics)
    //const mainTopic2=Object.values(topics2)

     const top=mainTopic[id]
     const top2=topics2[id]
      const imagePhoto=images[id]
      const title=texts[id]



    

      useEffect(() => {
        // Simulating a data loading delay
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }, []);
    //console.log(imagePhoto)
    


  const imageStyle = {
    backgroundImage: `url('${imagePhoto}')`,
  };


  return (
    <>
     <Helmet>
      <title>Practice Areas</title>
      <meta name="description" content="Our areas of practise"/>
      <link rel='canonical' href='/Practice Areas'/>
    </Helmet>
    {isLoading? <Loader2/> :
    (<>
     {/* <NavbarMain3/> */}
     <div className='mt-28'>
       <div className='w-full h-96 bg-black mt-16'>
        <div className='w-full h-1 bg-white'>
         {/* <div className="w-full h-96 bg-cover" style={imageStyle}></div> */}
         <div className='w-full h-96 relative' style={{backgroundImage:`url(${imagePhoto})`, backgroundAttachment:'fixed', backgroundPosition:'center', background:'cover'}}></div>
       
       </div> 
       <div>
         <Content top={top} top2={top2} title={title} />
       </div>
       
      
      
    {/* <h1>{topic.paragraph}</h1>
    <p>{topic.intro}</p>  */}
    </div>
    <div className='relative mt-[750px]'>
      <Footer3/> 
    </div>
    
   
      
     </div>
     </>)
     }
  </>
  )
}

export default TopicDetail


