import React from 'react'

const PageImg = () => {
  return (
    <div className='w-full h-56 mt-10'>
       <div  style={{ backgroundImage: `url(${'https://cdn.artphotolimited.com/images/59b65ee9faa2f46b71080369/1000x1000/colonnes.jpg'})`,backgroundAttachment:'fixed', backgroundPosition:'center', backgroundSize: 'cover',
          width: '100%',
          height: '100%',
  
       }} >
        <h1 className='text-6xl py-16 px-10 font-custom  text-left font-bold  text-yellow-700'>Appointment</h1>
     </div>
        

    </div>
  )
}

export default PageImg