import React from 'react'
import SectionWrapper from '../hoc/SectionWrapper'
import {BsFillBriefcaseFill} from 'react-icons/bs'
import CustomButton from './CustomButton'
import { Light } from '../assets'
import {motion} from 'framer-motion'
import { fadeIn } from '../utils/utils'


const importAll=(r)=>
  r.keys().reduce((acc,item)=>{
    acc[item.replace("./", "")] = r(item);
    return acc
  }, {})

 const heroTextureImports = importAll(
    require.context("../assets/articles", false, /\.(png|jpe?g|svg|gif)$/)
)

const articles=[1,2]
const Articles = () => {
  const images= Object.values(heroTextureImports);
  return (
    <div className='w-full h-[520px] mt-[5px] shadow-lg '>
       
        <div className='grid grid-cols-3 gap-3 h-full'>
        <motion.div variants={fadeIn("up", "spring", 1 * 0.5, 0.4)}>
          <div className='p-3'>
            <div className='flex flex-row space-x-3  mb-6'>
                 <img src={Light} className='w-[50px] h-[50px]'/>
              
              <h1 className='text-black font-custom text-2xl font-bold mt-6'>Latest Articles</h1>
            </div>
           

            <h1 className='font-custom text-left font-semibold'>Check Our Latest Articles and News</h1>
            <p className='text-left font-custom py-8'>"Discover insightful and up-to-date legal articles on our law firm's blog. 
              Stay informed about the latest legal trends, expert advice, 
              and case studies to empower yourself with valuable knowledge for your legal matters."</p>
              <div className='flex justify-start '>
                <CustomButton title='Blog Posts'/>
              </div>
         
        </div>
        </motion.div>
         <div className='flex flex-row gap-3 items-center h-3/4 col-span-2'>
         {/* {
           articles.map((article)=>( */}
          <>
            <div className='w-1/2 h-3/4 bg-white shadow-lg'>
              <img src={images[1]} style={{width: "100%",height:'100%',objectFit: "cover"}}/>
              <div className='mt-8 text-left font-custom'>
                <p  className='font-semibold text-2xl'>Provide insight into how commercial law for businesspeople can be used</p>
                <span className='hover:underline'>Read More___</span>
              </div>
              
            </div  >
            <div className='w-1/2 h-3/4 bg-white shadow-lg'>
               
               <img src={images[0]} style={{width: "100%",height:'100%',objectFit: "cover"}}/>
               <div className='mt-8 text-left font-custom'>
                  <p className='font-semibold text-2xl'>Discussing the practical use of Environmental Law to alleviate climate change</p>
                  <span className='hover:underline'>Read More___</span>
               </div>
              
            </div>
          </>
         {/* ))
        } */}

          {/* <div className='flex flex-row'>
              <h1 className='font-custom '> Blog post</h1>
            </div> */}
        </div>
       
     </div>
    </div>
  )
}

export default SectionWrapper(Articles, 'articles')