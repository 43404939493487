import logo from './logo.svg';
import { useEffect } from 'react';

import MainCarousel from './components/MainCarousel'
import Home from './pages/Home';
import Appointment from './pages/Appointment/Appointment';
import {BrowserRouter,createBrowserRouter, RouterProvider, Route, Routes, useLocation, useParams} from 'react-router-dom'
import NavbarMain from './components/NavbarMain';
import TopicDetail from './pages/Topic/TopicDetail';
import TopicDetail2 from './pages/Topic/TopicDetail2';
import Firm from './pages/Firm';
import ContactPage from './pages/Contact';
import Blog from './pages/BlogPage';
import Practise from './pages/Practise';
import Profile from './pages/Profile';
import Team from './pages/Team';
// import Blogs from './pages/Blogs';

// const router = createBrowserRouter([
//   {
//    path:'/',
//    element:<Home/>
//   },
//   {
//    path:'/Firm',
//    element:<Firm/>
//   },
//   {
//    path:'/Services',
//    element:<Practise/>
//   },
//   {
//     path:'/Blog',
//     element:<Blog/>
//    },
//    {
//     path:'/Appointment',
//     element:<Appointment/>
//    },
//    {
//     path:'/Services/:id',
//     element:<TopicDetail/>
//    },
//    {
//     path:'/Services/:id',
//     element:<TopicDetail2/>
//    },
//   // {
//   //  path:'/room/:id',
//   //  element:<RoomDetails />
//   // },
//   {
//    path:'/Contact',
//    element:<ContactPage/>
//   },
//   {
//     path:'firm/:id',
//     element:<Profile/>
//    },
 
//   // {
//   //  path:'/blog/:id',
//   //  element:<BlogDetails/>
//   // }
//  ]);

// const App = () => {
 
//  return (
//    <div >
   
//     {/* <Header/> */}
//       <NavbarMain/> 
//      <RouterProvider router={router}/>  
//      {/* <ArrowUp/> */}
//     {/* <Footer/> */}
   
   
//   </div>
//  ) 
 
// };

// export default App;




function App() {
  return (
  <div>
    <BrowserRouter>
  
       <NavbarMain/> 
       
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/:id" element={<TopicDetail/>}/>
          <Route path="Services/:id" element={<TopicDetail2/>}/>
          <Route path="/Firm/*" element={<Firm/>}/>
          <Route path="team/:id" element={<Profile/>}/>
          <Route path="/Services" element={<Practise/>}/>
          <Route path="/Team" element={<Team/>}/>
          <Route path="/Blog" element={<Blog/>}/>
          {/* <Route path="Blog/:id" element={<Blogs/>}/> */}
          <Route path="/Contact" element={<ContactPage/>}/>
          <Route path="/Appointment" element={<Appointment/>}/>
      
         
       
        </Routes>
  
    
    </BrowserRouter>
 
    </div>
  );
}

export default App;

{/* <BrowserRouter>
<Navbar/>
<ScrollToTop/>
  <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="item/:itemId" element={<ItemDetails/>}/>
    <Route path="checkout" element={<Checkout/>}/>
    <Route path="checkout/success" element={<Confirmation/>}/>
  </Routes>
<CartMenu/>
<Footer/>
</BrowserRouter> */}