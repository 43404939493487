import React,{useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { specialties } from '../../constants';
import Footer from '../../components/Footer';
import { useCarouselContext } from '../../context';
import firebase from "firebase/app";
import SectionWrapper from '../../hoc/SectionWrapper';
import { motion } from 'framer-motion';
import { slideIn } from '../../utils/utils';

const Content = ({top, top2, title}) => {

  
  return (
    <motion.div variants={slideIn('left', 'tween', 1, 1)} className='mt-[300px] flex flex-col items-center w-full  h-screen   font-custom text-xl text-black '>
      <div className='sm:w-2/3 w-3/4 text-yellow-700 bg-black opacity-95 z-10 sm:h-[1200px] h-[1700px] '>
      <div className='mt-1 font-custom font-bold sm:text-4xl text-2xl px-8 py-3 '>
         <h1 className='text-yellow-800 text-left'>{title}</h1>
        </div>
       <div className='sm:px-8 px-3 sm:py-2 py-1 sm:h-128 h-[1400px] overflow-hidden w-full'>
       {
      
       top2?.map((topic, index)=>(
          <div key={index} >
            <h1>{topic}</h1>
          </div>
        ))
       }
    
    
     {
     top?.length === 1 ? 
     <div></div> :
        top?.map((topic, index)=>(
          <div key={index} >
            <ul>
              <li>&#9679; {topic}</li>
            </ul>
          </div>
        ))
     
     }
     
     
    {/* <h1>{topic.paragraph}</h1>
    <p>{topic.intro}</p>  */}
   </div>
   </div>
  </motion.div>
  )
}

export default Content