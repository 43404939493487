import React, { useEffect, useState } from 'react'
import 'react-multi-carousel/lib/styles.css'; 
import { specialties } from '../constants';
import SectionWrapper from '../hoc/SectionWrapper';
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'; 
import { useCarouselContext } from '../context';
import {services} from '../constants/index';
import { Link } from 'react-router-dom';

const importAll=(r)=>
  r.keys().reduce((acc,item)=>{
    acc[item.replace("./", "")] = r(item);
    return acc
  }, {})

 const heroTextureImports = importAll(
    require.context("../assets/profs", false, /\.(png|jpe?g|svg|webp)$/)
)
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3, // Number of slides to display at a time on desktop
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2, // Number of slides to display at a time on tablet
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1, // Number of slides to display at a time on mobile
  },
};
const MiniCarousel2 = () => {
  const images= Object.values(heroTextureImports);
  const{currentIndex} = useCarouselContext

  const [isFlipped, setIsFlipped] = useState(null);

  const [isHovered, setIsHovered] = useState(null);

  const handleMouseEnter = (index) =>()=> {
    setIsHovered(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };


return (
  <div className='-mt-10 shadow-lg '>
  
       <div className='flex flex-wrap -mx-4'>  
        
         {services.map((image, index) => (
        // <div key={image.title} className={`relative aspect-w-1 aspect-h-1 border bg-yellow-800 hover:border-yellow-700 border-transarent `}>
        
          <div key={image.title} className={`w-full  h-48 sm:w-1/2 md:w-1/4 lg:w-1/4 mb-4 relative cursor-pointer `}     onMouseEnter={handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}>
              {/* <div className=' w-full h-full absolute z-40 inset-0  transform origin-middle border border-yellow-800 bg-white hover:h-0  '></div>
              <img className='object-cover object-center relative w-full h-full' alt='Image 1'  src={image.imgUrl} />
              <h1 className='font-custom -mt-20 font-semibold z-40 text-yellow-800 absolute '>{image.title}</h1> */}
               <div key={image.title} style={{width:'90%', height:'90%', }} className={`h-3/4 sm:w-1/2  object-center m-4 md:w-1/4 lg:w-1/4 mb-4 relative cursor-pointer border-2 bg-black   border-yellow-800 ${'glowing-div'}`}>
               <div key={image.title} style={{width:'90%', height:'90%', }} className='  h-3/4 sm:w-1/2  object-center m-2 md:w-1/4 lg:w-1/4  relative cursor-pointer clip-s-shape bg-slate-400'>
                   <div className={`w-full h-full absolute z-40 inset-0  transform origin-middle border-2 border-yellow-800 bg-black ${isHovered === index ? 'opacity-0' : 'opacity-100'} hover:translate-x-3`}></div>
                    <img className='object-cover object-center relative w-full h-full' alt='Image 1'  src={image.imgUrl} />
                    {/* <h1 className='font-custom  font-semibold z-40 text-2xl text-center  text-yellow-800 absolute -mt-32 p-4'>{isHovered === index ?  null : image.title}</h1>  */}
                    <a className={`font-custom  font-semibold z-40 text-2xl text-center ${isHovered === index ?   'text-yellow' : 'text-yellow-800'} absolute -mt-32 p-4`} href={`${image.title}`}>{image.main}</a>
               </div>
              </div>
         </div>

         
        //  <h1 className='font-custom mt-0 font-semibold z-10 text-white'>{image.title}</h1> 
        
      ))}
 </div>
</div>
)
}

export default SectionWrapper(MiniCarousel2, 'MiniCarousel') 