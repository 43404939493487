import React, {useState, useEffect, useRef} from 'react'
import MainCarousel from './MainCarousel'
import {logo} from '../assets'
import CustomButton from './CustomButton'
import { motion } from 'framer-motion'
import { intro } from '../constants'
import { navLinks } from '../constants'
import {AiOutlineMenuFold} from 'react-icons/ai'
import { Logo2 } from '../assets'
import { useCarouselContext } from '../context'
import { useNavigate } from 'react-router-dom'
import NavigationButton from './NavigationButton'


const Display = () => {
  const [onHover, setOnHover] =useState(false)
  const [index, setIndex] = useState(0)
  const[active, setActive]=useState("")
  const[toggle, setToggle]=useState(false)
  const {letOn}= useCarouselContext()
  const navigate=useNavigate()
  const contactsSectionRef = useRef(null);
  const {scrollToContacts} = useCarouselContext()

  const fadeInFromBelow = {
    hidden: { opacity: 0, y: 100 }, // Initial state: hidden and slightly below
    visible: { opacity: 1, y: 0 }, // Final state: visible and at its original position
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex((prevIndex) => (prevIndex + 1) % 3); // Reset to 0 when index reaches 2
    }, 5000);

    return () => clearTimeout(timer);
  }, [index]);

  
  const animationTransition = {
    duration: 0.8,
    loop: Infinity, // This will make the animation loop indefinitely
  };
  const animationVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  


  return (
    <div className=" w-full md:h-[550px]  h-[400px] mt-24 align-center relative  flex flex-col items-center justify-center ">
     <MainCarousel/>
  
        <motion.div
         initial="hidden"
         animate={letOn ? 'visible' : 'hidden'}
         variants={fadeInFromBelow}
         transition={{ duration: 1 }}
         className=" absolute flex flex-col items-center  z-30 md:w-1/3 w-2/3 md:mt-84 mt-48 ">
          <CustomButton
            title='Consultation'
            onClick={()=> scrollToContacts()}/>
        </motion.div>

        <div className="absolute inset-0 bg-gradient-to-t
         from-black via-black to-transparent
          opacity-60 
          h-1/4 
          md:py-[287px] py-[200px] 
          ">
        </div>  

     </div>
  )
}

export default Display