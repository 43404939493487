import React, {useState} from 'react'
import { BsFacebook } from 'react-icons/bs'
import { BsWhatsapp } from 'react-icons/bs'
import { AiOutlineTwitter } from 'react-icons/ai'
import {BsLinkedin} from 'react-icons/bs'
import { Logo2 } from '../assets'
import {FiSend} from 'react-icons/fi'
import { navLinks } from '../constants'
import { specialties } from '../constants'
import { Link } from 'react-router-dom'

const Footer = () => {
  const[active, setActive] = useState("")
  return (
    <div className='w-full h-1/3 sm:mt-[300px] mt-[500px] bg-yellow-900 relative shadow-lg overflow-x-hidden '>
       <div className='w-full sm:h-1/3 h-1/4 bg-black'>
         <div className=' flex space-x-4 justify-start text-2xl sm:py-20 py-24 md:px-72 px-12 hover:text-yellow-900 cursor-pointer' >
            <BsFacebook color='white' className='hover:text-yellow-900 cursor-pointer'/>
            <BsWhatsapp color='white' className='hover:text-yellow-900 cursor-pointer'/>
            <AiOutlineTwitter color='white' className='hover:text-yellow-900  cursor-pointer' />
            <BsLinkedin color='white' className='hover:text-yellow-900 cursor-pointer'/>
           </div>
           <div className='flex flex-row items-center absolute md:-mt-[220px] -mt-[230px] md:px-[600px] px-[10px]'>
              <form className='sm:px-64 px-8 sm:py-24 py-40'>
                <label>
                  <h1 className='text-left text-white font-custom'>Subscribe to Our Newsletter</h1>
                  <input
                  className='h-10 w-56 outline-none p-4'
                  placeholder='Email Address*'
                  />
                  <div className='absolute px-[223px] -mt-10 z-1'>
                     <div className='w-10 h-10 bg-yellow-900 flex items-center justify-center cursor-pointer'>
                          <FiSend/>
                     </div>
                  </div>
                   
                </label>
               
              </form>
          </div>
       </div>
       <div className='grid sm:grid-cols-4 sm:px-0 px-12 sm:gap-3  gap-0 sm:space-y-10  space-y-5 font-custom text-white'>
         <div className='-mt-8'>
           <img src={Logo2} alt='' className='sm:w-[400px] w-[200px] sm:h-[200px] h-[100px] mt-24 sm:p-6 p-2'/>  
         </div>
         <div className='mt-28 space-y-4'>
           <div className='flex flex-col space-y-2'>
           <h1 className='text-white font-bold'>Practice Area</h1> 
           {specialties.map((image, index) => (
             
              
                    <Link className='hover:text-black font-custom mt-5 text-1xl text-left font-bold z-10' to={`${image.title}`} >{image.role}</Link>

           ))}
           </div>
         </div>
         <div className='mt-28 space-y-2'>
         <h1 className='text-white font-bold'>Links</h1>
         <ul className='flex flex-col  space-y-1 '>
                 {navLinks.map((link)=>(
                    <li key={link.id} className={`${
                     active===link.title
                     ? "text-black"
                     : "text-white"
                   } hover:text-black text-[18px] font-custom cursor-pointer z-10`}
                    onClick={()=>setActive(link.title)}
                   >
                     
                      <a href={`#${link.id}`}>{link.title}</a>
                      {/* <span className="text-1xl inline-block px-2 rotate-90">&#8250;</span> */}
                     
                    </li>
                 ))}
                  
               </ul> 
         </div>
         <div className='mt-28 space-y-2'>
           <h1 className='text-white font-bold'>Our Address</h1>
           <div className=''>
            <h1>Britam Towers</h1>
            <h1>24th Floor, Regus, Suite 2b</h1>
            <h1>P.o Box 59091-00100</h1>
            <h1>Nairobi</h1>
            <h1>Office Line: +254 709 385 074</h1>
            <h1>Email: gekonge@gekongemomanyi.co.ke</h1>
           </div>
          
         </div>

       </div>
       
       <div className='text-yellow-600 font-secondary flex sm:mt-[16px] mt-4 sm:flex-row flex-col justify-center sm:px-0 px-12 sm:space-x-10 space-x-0  '>
         <span >Copyright 2024@Gekonge Momanyi & Associates LLP</span>
         <span>Private & Policy</span>
         <span>Terms</span>
         <span>About Us</span>
         <span>FAQ</span>
       </div>
    </div>
  )
}

export default Footer