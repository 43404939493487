import React from 'react'
import About from './About'

const Display2 = () => {
  return (
    <div className='w-full h-[900px]' >
        <div  style={{ backgroundImage: `url(${'https://cdn.artphotolimited.com/images/59b65ee9faa2f46b71080369/1000x1000/colonnes.jpg'})`,backgroundSize: 'cover', backgroundAttachment:'fixed', backgroundPosition:'center',
  backgroundPosition: 'center',
  width: '100%',
  height: '61%',
  
  // borderRadius:'4% 0% 0% 4%'
    }} >
           <About/>
        </div>
     
    </div>
  )
}

export default Display2