import React from 'react'

const Prof = ({id,profile}) => {
  return (
    <div>
       {profile.details.map((data) => (
        <div key={id} className='w-full h-[400px] relative' style={{backgroundImage:`url(https://www.legalnomads.com/wp-content/uploads/2023/01/Alternative-careers-for-lawyers-2023.png)`, backgroundAttachment:'fixed', backgroundPosition:'center'}}>
          <img src={data.img} alt="" className='absolute sm:top-12 top-36 sm:left-8 left-2 border-2 border-white sm:w-96 w-72 sm:h-96 h-72 object-center rounded-full' />
          {/* <h1 className='font-primary absolute font-semibold sm:text-7xl text-4xl text-left left-2 bottom-4 text-yellow-800 p-4'>Practise Areas</h1> */}
        </div>
         ))
        }
        <div className='w-full flex flex-col justify-center items-center'>
        <div className='w-full sm:px-36 px-8 pt-8'>
        {profile.details.map((data) => (
          <div className='flex sm:flex-row flex-col py-6'>
             {/* <div className='w-1/2  border-r-2 border-yellow-800'>
               <img src={prof} alt="prof" className='p-8' />
                <h1 className='text-xl text-yellow-800 font-secondary px-12'>Momanyi E. Gekonge - <span className='font-custom text-black'>Managing Partner</span> </h1>
             </div> */}
          <div className='sm:w-1/2 w-full sm:px-12 px-4 sm:border-r-2 border-r-0 sm:border-l-0 border-l-2 border-yellow-800 '>
            <h1 className='text-4xl text-yellow-800 font-custom'>{data.name}</h1>
            <h1 className='text-xl font-secondary'>{data.title}</h1>
           <p className=' py-6 font-custom'>
             {data.summary}  
          </p>
          </div>
          <div className='sm:w-1/2 w-full sm:pt-20 pt-6'>
          <h1 className='sm:px-12 px-4  pt-2 font-secondary'>Areas of Expertise:</h1>
          <ul className='list-disc sm:px-20 px-6 font-secondary text-yellow-800'>
          {data.expertise.map((data) => (
            <li>{data}</li>
             )
           )}
          </ul>
          </div>
          </div>
         ))}
           
        </div>
      </div>
    </div>
  )
}

export default Prof