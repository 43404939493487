import React, { useState } from 'react'
import Carousel from 'react-multi-carousel'
import { Link, useNavigate } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import CustomButton from './CustomButton2';
import { useCarouselContext } from '../context';
import PdfImageRenderer from './Pdf';
import PDF from '../assets/doc.pdf'


const carouselData = [
    {
      imageUrl: 'https://images.unsplash.com/photo-1532601224476-15c79f2f7a51?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDh8fGVuZXJneSUyMGxhd3xlbnwwfHwwfHx8MA%3D%3D',
      helper: 'Energy law',
      text:'Navigating Sustainable Energy Law',
    
    },
    {
      imageUrl: 'https://images.unsplash.com/photo-1528747354767-89476f71d2a3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Njh8fGNvbW1lcmNpYWwlMjBsYXd8ZW58MHx8MHx8fDA%3D',
      helper:'Corporate and Commercial Law',
      text:"Business Legal Compliance",
    },
    {
      imageUrl: 'https://images.unsplash.com/photo-1560518883-ce09059eeffa?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTN8fGVzdGF0ZSUyMGxhd3xlbnwwfHwwfHx8MA%3D%3D',
      helper:'Estate Planning & Probate Administration',
      text:"Estate Planning Essentials"
    },
]
export default function Blog () {
  const {isMobile, isMidMobile, blogs}  = useCarouselContext();
  const[blog,setIsBlog]=useState(false)
  const  navigate = useNavigate()

 let r=3
  if(isMidMobile){
    r=1
  } else{
    r=3
  }

  const toBlog=()=>{
    // if(!isAppointment){
      navigate('/Blog')
      setIsBlog((prevstate) => !prevstate)
    // }
    // navigate('/')
    // setIsAppointment(!isAppointment)
  }
  

  const dataArray = Object.entries(blogs).flatMap(([key, value]) => [key, value]);
  const filteredData = dataArray.filter(item => typeof item === 'object' && item !== null);
 

  return (
    <div className='sm:mt-96 mt-[500px] h-96 w-full flex sm:flex-row flex-col px-4 gap-4'>
      <div className='sm:w-1/3 flex align-center justify-center items-center flex-col space-y-2'>
        <h1 className='font-custom text-2xl'>News & Insights</h1>
        <h1 className='font-secondary text-yellow-800'>What is the latest news today?</h1>
        <CustomButton title={'Read More'} onClick={toBlog} />
     </div>
     {/* <PdfImageRenderer pdfUrl={PDF}/> */}
     <div className='sm:w-2/3'>
        <Swiper
         modules={[Navigation, Pagination, Scrollbar, A11y]}
         spaceBetween={20}
         slidesPerView={r}
         navigation
         pagination={{ clickable: true }}
         scrollbar={{ draggable: true }}
          onSlideChange={() => console.log('slide change')}
       //   onSwiper={(swiper) => console.log(swiper)}
         >
       {filteredData?.map((item, index)=>(
            <SwiperSlide key={index} className='bg-white  flex justify-center cursor-pointer' >
                 <PdfImageRenderer pdfUrl={item.imageURL}/>

                {/* <img src={item.imageURL} alt={`Slide ${index + 1}`} className='w-full h-96 sm:mt-7 mt-0  object-cover relative' style={{ backgroundAttachment:"fixed"}} /> */}
                {/* <div className='absolute top-0 z-10 w-48 h-48 left-4'> */}
              <div className="flex flex-col items-center h-24  bg-yellow-800 justify-center w-2/3 text-white font-custom text-lg text-center font-bold z-10 md:mt-48 mt-24 absolute bottom-0 shadow-xl shadow-black">
                <h1 className='text-white '>{item.title}</h1>
                  {/* <p className='font-custom sm:text-md text-md mt-5'>{item.text}</p> */}
                  {/* </div> */}
               </div>
            
          </SwiperSlide>     
          )) 
        }
        </Swiper>
      </div>
    </div>
    )
  }



// const Blog = () => {
//   return (
//     <div className='w-full h-96'>
//         <h3>Recent Articles</h3>
//         <h1>What is the latest news today</h1>
//         <div className=''>
//           <Carousel>
//             {carouselData.map((item, index) => (
//               <div key={index} className=' bg-white  flex justify-center'>
//                 <div className="flex flex-col items-center justify-center w-3/4 text-white font-custom text-4xl text-center font-bold z-10 md:mt-48 mt-24 absolute">
//                  <h1 className='text-yellow-600'>{item.helper}</h1>
//                  <p className='font-custom sm:text-5xl text-2xl mt-5'>{item.text}</p>
//                 </div>
//                   <img src={item.imageUrl} alt={`Slide ${index + 1}`} className='md:w-full w-1/2 md:h-[570px] h-[400px] sm:mt-7 mt-0  object-cover' style={{ backgroundAttachment:"fixed"}} />
//                </div>
//              ))}
//         </Carousel>
//       </div>
//     </div>
//   )
// }

// export default Blog