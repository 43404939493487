import React,{useState, useEffect, useRef} from 'react'
import {Logo2} from '../../assets'
import { motion } from 'framer-motion'
import {BsFacebook , BsWhatsapp} from 'react-icons/bs'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiOutlineMenuFold} from 'react-icons/ai'
import SectionWrapper2 from '../../hoc/SectionWrapper2'
import CustomButton2 from '../../components/CustomButton2'
import { Link, useNavigate } from 'react-router-dom'
import { specialties } from '../../constants'
import { fadeIn } from '../../utils/utils'
import { navLinks } from '../../constants'


const fadeInFromBelow = {
  hidden: { opacity: 0, y: -20 }, // Initial state: hidden and slightly below
  visible: { opacity: 1, y: 0 }, // Final state: visible and at its original position
};

const Navbar = () => {
  const[active, setActive] = useState("")
  const[toggle, setToggle] = useState(false)
  const[appear, setAppear] = useState(false)
  const navigate = useNavigate()
  const [isAppointment, setIsAppointment] = useState(true)
  const [isHovered, setIsHovered] = useState(false);
  const divRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  //console.log(navLinks)

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsHovered(false);
      setIsOpen(false)
    }
  };

 
  useEffect(() => {
    if(isOpen){
      document.addEventListener('click', handleClickOutside);
      
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);


  const toggleDiv = () => {
    setIsHovered(true);
   setAppear(true)
    setTimeout(() => {
      setIsOpen(true);
    }, 50);
     
  };


  const toggleDiv2 = () => {
    setIsHovered(false);
   setAppear(false)
    setTimeout(() => {
      setIsOpen(false);
    }, 50);
     
  };
  // useEffect(()=>{
  //   setTimeout(() => {
  //     setIsOpen(false);
  //   }, 1000);

  // },[isOpen])


  
  
  // const handleMouseLeave = () => {
  //   setIsHovered(!isHovered);
  // };

  const toAppointment=()=>{
    // if(!isAppointment){
      navigate('/Appointment')
      setIsAppointment((prevstate) => !prevstate)
    // }
    // navigate('/')
    // setIsAppointment(!isAppointment)
  }
  return (
    <div className='w-full h-28 bg-white z-50 -mt-[165px] shadow-xl'>
      <Link to='/' onClick={()=>{window.scrollTo(0,0)}}>
       <img
         src={Logo2}
         alt='logo'
         className='lg:w-[200px] w-[130px] h-[95px] absolute sm:left-16  left-8 object-contain cursor-pointer sm:mt-5 mt-7 text-4xl z-50'
        />
      </Link>  
       <div className='absolute lg:mx-80 mx-60 mt-8 md:block hidden'>
           <ul className='flex flex-row lg:space-x-8 space-x-4 mt-8 '>
            {navLinks.slice(5).map((link)=>(
               <li key={link.id} className={`${
                active===link.title
                ? "text-yellow-800"
                : "text-black"
              } hover:text-yellow-800 lg:text-[18px] text-[17px] font-semibold font-custom cursor-pointer z-10`}
               onClick={()=>setActive(link.title)}
              >
                  
                    <a href={`#${link.id}`}>{link.title}</a>
                
                 
                  
              </li>
            ))}
          </ul>
          <span className={`text-3xl inline-block px-[365px] rotate-90 cursor-pointer -mt-8`}  onClick={toggleDiv}>&#8250;</span> 
          {isHovered && appear && (
            <motion.div 
            initial="hidden"
            animate={appear ? 'visible' : 'hidden'}
            variants={fadeInFromBelow}
            transition={{ duration: 0.5 }}
             ref={divRef}
            className='bg-black w-64 overflow-y-scroll max-h-[550px] mx-[250px] absolute'>
            {specialties.map((image, index) => (
              <Link to={`${image.title}`} >
              <div key={image.title} onClick={toggleDiv2} className={`w-full h-1/3  items-center hover:from-yellow-800  hover:to-black hover:bg-gradient-to-b transition duration-300 hover:text-white text-yellow-800 cursor-pointer `}>
              <img
                  key={image}
                  src={image.icon}
                  alt={`Slide ${index + 1}`}
                  className={`w-full h-16 `}
                 // className={`w-1/3`}
              />
                 <div className='flex flex-col items-center'>
                    <Link className='font-custom mt-5 text-1xl text-center px-8 font-bold z-10' to={`${image.title}`} >{image.role}</Link>
                 </div>
                     
           
               {/* <h1 className='font-custom mt-5 text-1xl text-center font-bold z-10 '>{image.role}</h1> */}
              </div>
              </Link>
            ))}
           </motion.div>
          )}    
        </div>
          {/* {MOBILE} */}
    <div className='md:hidden block'>
            <div className='absolute right-12 mt-12 block md:hidden z-50'>
               <AiOutlineMenuFold color="#b8860b" size={30}
               onClick={()=>setToggle(!toggle)}
               />
    
            </div>
            {
              toggle && (
                <div className={` bg-black absolute w-56 h-screen right-0 `}>
                <ul className='flex flex-col  space-y-1 mt-24 p-4'>
                 {navLinks.map((link)=>(
                    <li key={link.id} className={`${
                     active===link.title
                     ? "text-yellow-800"
                     : "text-white"
                   } hover:text-yellow-800 text-[18px] font-custom cursor-pointer z-10`}
                    onClick={()=>setActive(link.title)}
                   >
                     
                      <a href={`#${link.id}`}>{link.title}</a>
                      {/* <span className="text-1xl inline-block px-2 rotate-90">&#8250;</span> */}
                     
                    </li>
                 ))}
                  
               </ul> 
               <div className='absolute mt-2  w-48 left-2  '>
                <CustomButton2
                 title='Appointment'
                 onClick={()=>setIsAppointment(!isAppointment)}/>
                </div>
            </div>   
              )
            }
       
          


        </div>
        <div className='absolute mt-12  lg:-right-12 -right-6 lg:w-96 w-72 md:block hidden '>
          <CustomButton2
          title='Appointment'
          onClick={toAppointment}
          />
        </div>
   </div>
  )
}

export default SectionWrapper2(Navbar, 'Navbar') 