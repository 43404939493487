import React,{useState,useEffect} from 'react'
import Loader2 from '../hoc/Loader2'
import MiniCarousel2 from '../components/MiniCarousel2'
import NavbarMain from '../components/NavbarMain'
import Footer from '../components/Footer'
import MiniCarousel3 from '../components/MiniCarousel3'

const Practise = () => {
  const [isLoading, setIsLoading] = useState(true);
  

    useEffect(() => {
        // Simulating a data loading delay
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }, []);
  return (
    <>
    { isLoading? <Loader2/> :
    <div>
        <div className='pt-24'>
            <NavbarMain/>
        </div>
        <div className='w-full h-96 relative' style={{backgroundImage:"url('../blog9.jpg')", backgroundAttachment:'fixed', backgroundPosition:'center', background:'cover'}}>
        
      </div>
        <div className='w-full h-full flex justify-center items-center pt-4'>
           <h1 className='font-custom sm:text-4xl text-2xl text-left left-2 bottom-4 text-yellow-800 p-4'>Practise Areas</h1>
        </div>
        <section className=''>
           <MiniCarousel3/>
        </section>
     
        <Footer/>
    </div>
  }
  </>
  )
}

export default Practise