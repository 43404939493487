import React, { useEffect, useState } from 'react'
import 'react-multi-carousel/lib/styles.css'; 
import { specialties } from '../constants';
import SectionWrapper from '../hoc/SectionWrapper';
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'; 
import { useCarouselContext } from '../context';

const importAll=(r)=>
  r.keys().reduce((acc,item)=>{
    acc[item.replace("./", "")] = r(item);
    return acc
  }, {})

 const heroTextureImports = importAll(
    require.context("../assets/profs", false, /\.(png|jpe?g|svg|webp)$/)
)
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3, // Number of slides to display at a time on desktop
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2, // Number of slides to display at a time on tablet
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 3, // Number of slides to display at a time on mobile
  },
};
const MiniCarousel = () => {
  const images= Object.values(heroTextureImports);
  const{currentIndex} = useCarouselContext
//   return (
//     <div className='w-full h-[180px] bg-yellow-700 rounded-2xl flex flex-row -mt-[175px] -py-10 shadow-xl' >
//      {/* <Carousel infinite responsive={responsive} axis="horizontal"> */}
//        {/* {specialties.map((special, index) => (
//          <div key={special.title} className={`w-1/4 relative items-center hover:bg-white`}>
      
//           <img
//             key={special.id}
//             src={special.icon}
//             alt={`Slide ${index + 1}`}
//            className={`h-full py-1 absolute left-2 ` }
//           />
//         <h1 className='font-custom mt-5 font-semibold z-10 text-white'>{special.title}</h1>
        
//      </div>
//      ))} */}
     
//      {/* </Carousel> */}
//   <Carousel
//            responsive={responsive}
//            infinite
//            //infinite
//           //  arrows={false} // Optional: Hide arrows for a cleaner look
//           //  showDots={false} // Optional: Hide dots for a cleaner look
//           //  swipeable={true} // Enable swipe gestures
//           //  draggable={true} // Enable mouse dragging
//           //  ssr={false} // Server-side rendering (set to false for client-side rendering)
//           //  keyBoardControl={true} // Enable keyboard control
//           //  transitionDuration={500} // Set the slide transition duration (in milliseconds)
//           //  containerClass="carousel-container" // Optional: Custom CSS class for the carousel container
//           //  itemClass="carousel-item" // Optional: Custom CSS class for the carousel items
//            axis="horizontal" // Set the sliding direction to horizontal> 
//           >
//            {images.map((image, index) => (
//           <img
//               key={image}
//               src={image}
//               alt={`Slide ${index + 1}`}
//                className={`group-hover:bg-white` }
//              // className={`w-1/3`}
//           />
          
//         ))}
//         {/* <div className=' flex z-10 absolute mt-10'>
//           {
//            names.map((name)=>(
//             <> 
//               <h1>{name.title}</h1>
//               <p>{name.role}</p>
//             </>
//           ))
//           }
//         </div> */}
       
//      </Carousel> 
  
// </div>
//   )

return (
  <div className='sm:mt-2 mt-0 shadow-lg bg-white'>
      <div className='flex flex-col items-center py-6'>
        <h1 className='font-custom font-bold text-2xl mb-5 text-yellow-800 '>Our Services</h1>  
        <p className='sm:w-1/2 text-center font-secondary sm:text-md text-sm'>At our esteemed law firm, our team stands as the cornerstone of our commitment to delivering exceptional legal services. </p>
      </div>
      <Carousel
         responsive={responsive}
         infinite
         infiniteLoop={true}
         autoPlay={true}
         interval={5000}
         transitionTime={500}
         arrows={false} // Optional: Hide arrows for a cleaner look
         swipeable={true} // Enable swipe gestures
         draggable={true} // Enable mouse dragging
         transitionDuration={500} // Set the slide transition duration (in milliseconds)
         axis="horizontal" // Set the sliding direction to horizontal> 
        > 
         {specialties.map((image, index) => (
        <div key={image.title} className={`w-full p-0 relative sm:h-full h-3/4  items-center hover:from-yellow-800 hover:to-black hover:bg-gradient-to-b transition duration-300 hover:text-white text-yellow-800 cursor-pointer `}>
            <img
               key={image}
            src={image.icon}
            alt={`Slide ${index + 1}`}
               className={`w-full h-1/3 `}
                // className={`w-1/3`}
             />
             <h1 className='font-custom mt-5 text-1xl text-center font-bold z-10 '>{image.role}</h1>
             <p className='font-custom absolute p-4 text-center sm:block hidden '>{image.intro}</p>
        </div>
      ))}
  
     
   </Carousel> 
 
 </div>
)
}

export default SectionWrapper(MiniCarousel, 'MiniCarousel') 