import React from 'react'
import Navbar from './Navbar'


const NavbarMain = () => {
  
  return (
    <div className='w-full h-1 fixed z-40'>
      <Navbar/>
    </div>
  )
}

export default NavbarMain