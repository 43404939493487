import React,{useEffect,useState} from 'react'
import Loader2 from '../hoc/Loader2';
import NavbarMain from '../components/NavbarMain';
import Footer from '../components/Footer';
import Cv from './Cv';

const Team = () => {
    const [isLoading, setIsLoading] = useState(true);
    

    useEffect(() => {
        // Simulating a data loading delay
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }, []);

     
  return (
    <>
    { isLoading? <Loader2/> :
    <div className='bg-white ' >
      <NavbarMain/> 
      <div className='w-full h-96 flex flex-col justify-end  mt-24' style={{backgroundImage:`url(https://www.legalnomads.com/wp-content/uploads/2023/01/Alternative-careers-for-lawyers-2023.png)`, backgroundAttachment:'fixed', backgroundPosition:'center', background:'cover'}}>
        {/* <h1 className='font-primary font-bold sm:text-4xl text-xl text-left px-8 text-yellow-800 p-4'>Overview <br/> of the Firm</h1> */}
      </div>
     
      <div className='md:mt-20 mt-0'>
        <Cv/>
      </div>
      
      <Footer/>
    </div>  
      }
    </>
  )
}

export default Team