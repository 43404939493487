import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'; 
import { testimonials } from '../constants'
import SectionWrapper from '../hoc/SectionWrapper'
import { Testimony } from '../assets';
import {motion} from 'framer-motion'
import { fadeIn } from '../utils/utils';
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items:2, // Number of slides to display at a time on desktop
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2, // Number of slides to display at a time on tablet
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1, // Number of slides to display at a time on mobile
  },
};
const dotStyles = {
  background: 'red', // Set the desired color for the dots
  borderRadius: '50%', // Optional: Make the dots circular
  width: '8px', // Set the width of each dot
  height: '8px', // Set the height of each dot
  margin: '0 5px', // Set the spacing between dots
};
const Testimonials = () => {
  return (
    <div className='grid grid-cols-3 gap-10 mt-6 shadow-lg'>
      <motion.div variants={fadeIn("up", "spring", 1 * 0.5, 0.4)}>
      <div className='p-2'>
        <div className='flex flex-row space-x-2'>
         <img src={Testimony} className='w-[50px] h-[50px]'/>
         <h1 className='flex justify-start font-custom text-2xl mt-5'>Testimonials</h1>
        </div>
       <h1 className='font-medium text-slate-950 text-3xl text-left mt-8'>What Our Clients Say About Us...</h1>
       {/* <p className='mt-5'>After consulting multiple law firms, I chose XYZ Law Firm, 
        and it turned out to be the best decision I made.
         Their team of lawyers demonstrated an exceptional level of professionalism and expertise.
      </p> */}
     </div> 
     </motion.div>
    <div className='w-full h-full py-7 col-span-2 mt-10'>
        <Carousel
        responsive={responsive}
        arrows={false}
        showStatus={false}
        showDots={true}
      //  customDot={dotStyles}
        renderDotsOutside={false}
        >
            {testimonials.map((data)=>(
            <div className=' p-3'>
                <p className='font-custom text-left text-yellow-600'>"{data.testimony}"</p>
                <div className='flex flex-col'>
                  <img/>
                  <h1 className='font-custom mt-3 font-bold text-left px-2'>{data.title}</h1>
                   <h1 className='font-custom font-bold text-left px-2'>{data.occupation}</h1>
                </div>
            </div>
            ))}
        </Carousel>
    </div>

    </div>
  )
}

export default SectionWrapper(Testimonials, 'testimonials')