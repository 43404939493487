import React from 'react'
import { prof,prof2, prof3,prof4 } from '../assets';

const profiles = [
    { 
     id:0,
     img:prof,
     name:'Momanyi E. Gekonge',
     role:'Managing Partner'
    },
    {
     id:1,
     img:prof2,
     name:'Arnold Mugogo',
     role:'Partner and Head of Litigation Department'
    },
    {
     id:2,
     img:prof3,
     name:'Brian Mogaka',
     role:'Partner and Head of Corporate and Real Estate Department'
    },
    {
      id:3,
      img:prof4,
      name:'Mugendi Gachuba',
      role:'Partner and Head of Commercial and Tax Department'
     },
  ]
const Partners = () => {
  return (
    <div>
          <div className='w-full md:h-96 h-[1200px] flex  flex-col justify-center items-center '>
        <h1 className='font-custom text-yellow-800'>OUR TEAM</h1>
        <p className='fot-custom text-2xl'>Meet Our Partners</p>
        <div className='w-full  sm:px-36 px-4 pt-8'>
           <div className='flex md:flex-row flex-col gap-2 '>
         
              {profiles.map((profile,index) => (
              <a href={`/team/${profile.id}`}  className='group md:w-1/3 flex flex-col justify-center items-center cursor-pointer hover:bg-yellow-800 hover:text-black p-4'>
               <img src={profile.img} alt="prof" className='rounded-full w-48 h-48 ' />
               <div className='py-3 text-center'>
                 <h1 className='text-xl text-yellow-800 font-secondary group-hover:text-white'>{profile.name}</h1>
                 <span className='font-custom text-black'>{profile.role}</span> 
               </div>
               </a>
              )
            )}
               {/* <a href="/0" className='group md:w-1/3 flex flex-col justify-center items-center cursor-pointer hover:bg-yellow-800 hover:text-black p-4'>
               <img src={prof} alt="prof" className='rounded-full w-48 h-48 ' />
               <div className='py-3 text-center'>
                 <h1 className='text-xl text-yellow-800 font-secondary group-hover:text-white'>Momanyi E. Gekonge </h1>
                 <span className='font-custom text-black'>Managing Partner</span> 
               </div>
               </a> */}
          
          
             {/* <a href="/1" className=' group md:w-1/3 flex flex-col justify-center items-center cursor-pointer hover:bg-yellow-800  hover:text-black'>
               <img src={prof2} alt="prof" className=' w-48 h-48 rounded-full' />
               <div className='py-3 text-center'>
                <h1 className='text-xl text-yellow-800 font-secondary group-hover:text-white'>Arnold Mugogo</h1>
                <span className='font-custom text-black'>Partner and Head of Litigation Department </span> 
               </div>
             </a> */}
             {/* <a  href={`firm/1`} className='group md:w-1/3 w-full flex flex-col justify-center items-center text-center cursor-pointer hover:bg-yellow-800 hover:text-black'>
               <img src={prof3} alt="prof" className='w-48 h-48 rounded-full'/>
               <div className='py-3 text-center'>
                 <h1 className='text-xl text-yellow-800 font-secondary group-hover:text-white '>Brian Mogaka </h1>
                 <span className='font-custom text-black ' >Partner and Head of Corporate and Real Estate Department </span> 
               </div>
             </a> */}
          </div>
        </div>
        </div>
    </div>
  )
}

export default Partners